.product-dropdown {
  margin-top: 1em;

  &.dropdown {
    position: relative;
    overflow: visible;

    a.toggle {
      display: block;
      text-decoration: none;
      background-color: color("white");
      color: color("red");
      font-weight: 900;
      text-transform: none;
      border: 1px solid color("lightgray");
      text-align: left;
      padding: .5em 1em;
      margin: 0;
      height: 50px;
      position: relative;
      overflow: hidden;

      span.caret {
        position: absolute;
        right: 0.556em;
        top: 0.3em;
        color: color("red");
        font-weight: bold;
        line-height: 1.8em;
        transition: all ease .3s;
      }

      &.open span.caret {
        transform: rotate(180deg);
      }

      &.product-open span.caret {
        transform: rotate(180deg);
        color: color("white");
      }

    }

    a.toggle.open {
      background-color: color("red");
      color: color("white");

      span.caret {
        color: color("white");
      }
    }
  }
  .dropdown-content {
    position: absolute;
    background-color: color("white");
    width: 100%;
    height: 11.111em;
    overflow: auto;
    border: 1px solid color("red");
    border-top: 0;
    z-index: 86;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: block;

        a {
          display: block;
          background: color("white");
          color: color("darkgray");
          text-decoration: none;
          padding: .5em 0 .5em .5em;
          line-height: 1.5em;
          border-bottom: 0.056em solid color("lightgray");

          &:hover {
            background-color: color("lightgray");
            color: color("white");
          }
        }
      }
    }
  }

  .toggler {
    display: block;
    background-color: color("white");
    text-align: center;
    padding: 0.5em;
    border-bottom: 0.056em solid color("darkgray");
    position: relative;
    font-weight: bold;
  }
}