.page-node-661 {
  #content .container {
    max-width: 100%;
  }

  #breadcrumb {
    background-color: color("red");
    margin: 0;
    padding: 1em 1em 0 1em;

    .breadcrumb {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;

      a, .delimiter {
        color: color("white");
      }
    }
  }

  .parts-header {
    background-color: color("red");
    padding: 0 1em;
    margin-bottom: 3em;

    .team-header {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;
      color: color("white");
    }
  }

  .parts-body {
    max-width: 1416px;
    min-width: 320px;
    padding: 0 1em;

    h4 {
      text-transform: none;
      margin-right: 1em;
    }

    button {
      @include media($bp-smdesktop) {
        margin-bottom: 1em;
      }
      @include media($bp-desktop) {
        width: 20%;
      }
    }
  }

}