/* For the main content on BOTH Industries and Services */
.node-industry-and-service,
.node-industry {
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-flow: row wrap;
  align-content: flex-end;

  h1 {
    margin-bottom: 0;
    text-transform: capitalize;
    color: color("darkgray");

    @include media($bp-tablet) {
      text-align: left !important;
      margin-left: 20px;
      width: 100%;
    }
  }

  .field-name-body {
    margin: 0 20px 20px;
    text-align: justify;

    @include media($bp-tablet) {
      width: 55%;
      float: left;
      margin-right: 5px;
      padding-right: 25px;
    }

    .industry {
      width: 65%;
      text-transform: uppercase;

      @include media($bp-smdesktop) {
        width: 40%;
      }
    }
  }

  .field-type-image img {
    width: 100%;
    height: 100%;

    @include media($bp-tablet) {
      width: 40%;
      max-width: 700px;
      float: right;
      margin-top: -35px;
    }

    @include media($bp-smdesktop) {
      margin-right: 25px;
    }
  }
}

/* block that shows on the services pages under content and above locations */
.customer-support-block {
  background-image: url('/sites/default/files/customer-support.jpg');
  background-size: cover;
  height: 440px;
  margin-top: 5%;

  .content {
    color: color("white");
    width: 100%;
    text-align: center;
    margin: 0 auto;
    max-width: 1416px;

    h2 {
      font-weight: 900;
      padding-top: 1em;
      margin-bottom: 0;
    }

    h5 {
      text-transform: none;
      font-weight: 900;
      margin-top: 0;
    }

    p:last-child {
      @include media($bp-tablet) {
        display: flex;
      }
    }

    button {
      border: none;
      margin-left: 5%;
      margin-bottom: .5em;

      @include media($bp-smdesktop) {
        width: 45%;
        margin-left: 5%;
      }
    }

    button:first-child {
      margin-left: 0 !important;
    }

    @include media($bp-tablet) {
      width: 40%;
      margin-left: 15%;
      margin-top: 5%;
      height: 400px;
    }
  }

  /* For only making sure the person is showing on tablet */
  @include media($bp-tablet) {
    background-position: 60%;
  }
}
