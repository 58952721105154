.page-support-warranty-information {
  #content .container {
    max-width: 100%;
  }

  #breadcrumb {
    background-color: color("red");
    margin: 0;
    padding: 1em 1em 0 1em;

    @include media($bp-landscape) {
      padding-top: 2em;
    }

    @include media($bp-tablet) {
      padding-top: 1em;
    }

    .breadcrumb {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;

      a, .delimiter {
        color: color("white");
      }
    }
  }

  .view-warranty-information {
    .view-header {
      .header-wrapper {
        background-color: color("red");
      }
      
      margin-bottom: 6em;

      .team-header {
        max-width: 1416px;
        min-width: 320px;
        margin: 0 auto;
        color: color("white");
        padding-left: .5em;

        //@include media($bp-desktop) {
        //  margin: 0 1em;
        //}

        @include media($bp-lgdesktop) {
          margin: 0 auto;
        }
      }

      .warranty-header {
        background-color: color("white");
        padding-left: .5em;

        #warranty-info {
          max-width: 1416px;
          min-width: 320px;
          margin: 0 auto;
          padding: 1em 0;
          height: 240px;

          h2, p {
            color: color("darkgray");
            text-align: left;
            float: left;

            @include media($bp-smdesktop) {
              width: 70%;
            }
          }

          h2 {
            margin-bottom: 0;
            font-weight: 900;
            font-size: 1em;

            @include media($bp-tablet) {
              font-size: 1.94em;
            }

            @include media($bp-desktop) {
              font-size: 2.222em;
            }
          }

          p {
            margin-top: 0;
            margin-bottom: 0;
          }

          a {
            color: color("red");
            font-weight: 900;
            padding-left: 5px;
          }

          img {
            float: left;
            height: 240px;
            padding-right: 2em;
          }
        }
      }
    }

    .view-content {
      max-width: 1416px;
      min-width: 320px;
      margin: 0;

      .ui-accordion-content, .views-accordion-header{
        padding-left: 1em;

        @include media($bp-tablet){
          padding-left: 0;
        }
      }

      @include media($bp-tablet){
        margin: 2em 1em;
      }

      @include media($bp-desktop) {
        margin: 2em;
      }

      @include media($bp-lgdesktop) {
        margin: 2em auto;
      }
    }

    .views-row {

      .views-accordion-header {
        border-right: none;
        border-left: none;
        border-top: 1px solid color("lightgray");
        border-bottom: 1px solid color("lightgray");
      }

      .ui-accordion-content {
        border: none;
      }

      a {
        color: color("red");
        font-weight: 900;

        text-transform: none;

        @include media($bp-smdesktop) {
          font-size: 1.2em;
        }
      }

      .ui-accordion-header {
        a {
          &:after {
            content: '\f078';
            font-family: FontAwesome;
            color: color("red");
            float: right;
            position: absolute;
            padding-right: 1%;
            right: 0;
          }
        }
      }

      .ui-accordion-header-active {
        a {
          &:after {
            content: '\f077' !important;
            font-family: FontAwesome;
            color: color("darkgray");
            position: absolute;
            padding-right: 1%;
            right: 0;
          }
        }
      }

      .ui-accordion-header-active {
        a {
          color: color("darkgray");
        }
      }

      .ui-accordion-header-icon {
        display: none;
      }
    }

    .webform-component {
      input {
        //width: 100%;
      }

      input[type=radio],
      input[type=checkbox] {
        width: 2%;
      }
    }

    .webform-layout-box.equal,
    .webform-layout-box.horiz {
      @include media($bp-smdesktop) {
        width: 45%;
        float: left;
        margin: 0 .5em;
      }
    }

    .webform-component-markup {
      border-bottom: 1px solid color("red");
      width: 100%;
      font-weight: 900;
    }

    .webform-layout-box.horiz > * + * {
      margin-left: 0;
    }

    .webform-layout-box.equal.child-width-4 > * {
      width: 100%;
    }

    .form-actions {
      width: 100%;
      clear: both;
      text-align: center;

      .webform-submit {
        &.button-primary {
          background-color: color("red");
          color: color("white");
          font-family: $button-font;
          padding: 5px 0;
          border-radius: 24px;
          border: 1px solid color("white");
          margin-left: 10px;
          height: 48px;
          width: 30%;
        }
      }
    }


    .ui-accordion-header {
      background: transparent !important;
    }
  }
}