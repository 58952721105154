/* FRONT Product Finder */
.find-products {
  background-color: color("white");
  color: color("darkgray");
  margin-top: 2em;
  padding-bottom: 2em;

  @include media($bp-tablet) {
    background-color: color("darkgray");
    color: color("white");
    margin-top: 0;
  }

  @include media($bp-smdesktop) {
    flex: 1 70%;
    margin-right: 2%;
    padding-bottom: 0;
  }

  @include media($bp-lgdesktop) {
    padding-bottom: 1.38em;
  }

  /* Front page, interior of product finder */
  .product-finder {
    width: 95%;
    margin: 0 auto;

    h2 {
      text-align: center;
      font-weight: 900;
      //padding-top: 1em;
      line-height: 1.2em;

      @include media($bp-tablet) {
        padding-top: 1em;
      }

      @include media($bp-smdesktop) {
        margin: 0.5em auto 0 auto;
        padding-top: 0;
        font-size: 2em;
      }

      @include media($bp-lgdesktop) {
        font-size: 2.222em;
      }
    }

    .intro {
      @include media($bp-smdesktop) {
        width: 40%;
        text-align: center;
        float: left;
      }
    }

    .intro p {
      margin-bottom: 20px;
    }
  }

  .product-search-group {

    @include media($bp-landscape) {
      width: 45%;
      float: left;
      margin: 0 2%;
    }
    @include media($bp-smdesktop) {
      width: 28%;
      float: left;
      margin: 4% 0 0 2%;
    }
  }
}

/* Products Landing Page */
/* FRONT Product Finder */
.products-find-products {
  background-color: color("darkgray");
  color: color("white");
  margin: 2.3em 0;
  padding: 0 1em 2em;

  h2 {
    margin: 0;
    text-align: center;
    font-weight: 900;
  }

  @include media($bp-landscape) {
    padding-bottom: 5em;
    margin-top: 3.7em;
  }

  @include media($bp-tablet) {
    margin-top: 4.2em;
  }

  @include media($bp-smdesktop) {
    margin-top: 3.2em;
  }

  @include media($bp-desktop) {
    margin-top: 1.7em;
  }

  @include media($bp-lgdesktop) {
    margin-top: 0.111em;
  }

  .product-dropdown.dropdown {
    @include media($bp-tablet) {
      float: left;
      min-width: 25%;
      margin: 1em auto;
    }

    @include media($bp-smdesktop) {
      min-width: 20%;
      margin: 1em;
    }

    @include media($bp-lgdesktop) {
      min-width: 22%;
    }
  }

  .product-search-group {

    @include media($bp-landscape) {
      width: 45%;
      float: left;
      margin: 0 2%;
    }
    @include media($bp-smdesktop) {
      width: 28%;
      float: left;
      margin: 15% 0 0 2%;
    }

    @include media($bp-desktop) {
      margin-top: 4%;
    }
  }
}

/* Front Featured Products */
 .featured-products {
   width: 100%;
   display: flex;
   justify-content: center;

   .content {
     width: 100%;
   }

   h2 {
     text-align: center;
     font-weight: 900;
     color: color("darkgray");


     @include media($bp-desktop) {
       margin-bottom: 1em;
     }
   }

   .view-content {
     .views-row {
       text-align: center;
       margin-bottom: 3em;

       @include media($bp-landscape) {
         width: 45%;
         float: left;
         margin: 2%;
         min-height: 320px;
         vertical-align: bottom;
       }

       @include media($bp-tablet) {
         width: 45%;
         float: left;

       }

       @include media($bp-smdesktop) {
         width: 23%;
         margin: 0 1%;
         height: 450px;
       }

       @include media($bp-lgdesktop) {
         height: 350px;
       }

       .views-field-field-image {
         min-height: 230px;
       }

       img {
         width: 100%;
         height: 25%;

         @include media($bp-smdesktop) {
           max-height: 235px;
           height: auto;
         }
       }

       p {
         margin: 0;
         font-family: $button-font;
         color: color("red");
         text-align: center;
         line-height: 1.5em;
         width: 90%;
       }
     }
   }
 }

 .page-node-647 {
   .breadcrumb {
     display: none;
     visibility: hidden;
   }
 }
.view-product-finder {
  img {
    max-width: 100%;
    height: auto;
  }
}
