.region-nav { // had to specify to distinguish from footer
  // For the mobile menu
  ul.menu {
    display: none;
    visibility: hidden;
  }
  // For the small desktop and up
  @include media($bp-smdesktop) {
    ul.menu {
      clear: both;
      padding: 0;
      margin-top: 17px;
      margin-bottom: 0;
      margin-left: 1em;
      list-style: none;
      display: block;
      visibility: visible;

      @include media($bp-desktop) {
        z-index: -5;
        margin-top: 0;
      }

      @include media($bp-lgdesktop) {
        margin-left: 0;
      }

      .leaf {
        list-style: none;
        white-space: nowrap;
      }

      li {
        list-style: none;
        position: relative;
        padding: 0;
        margin: 1em 0.111em;
        display: inline-block;
        font-size: 0.8em;


        @include media($bp-desktop) {
          margin: 1em 0.48em;
        }

        @include media($bp-lgdesktop) {
          margin: 1em 0.45em;
          font-size: 1em;
        }

        a.active-trail {
          border-bottom: 2px solid color("red");
        }

        .menu, ul {
          position: absolute;
          top: 5.8em;
          padding: 1em;
          margin-top: -0.056em;
          background-color: transparent;
          border: 2px solid color("red");
          left: 0;
          display: block;

          @include media($bp-smdesktop) {
            top: 2.2em;
          }

          @include media($bp-desktop) {
            top: 2.2em;
          }

          @include media($bp-lgdesktop) {
            top: 2em;
          }

          li {
            margin: 0;
            width: 100%;
            text-transform: none;

            a {
              color: color("darkgray");
              transition: all ease 0.3s;
              opacity: 1;

              &.active {
                background-color: peach;
              }
            }

          }

          a {
            color: color("lightgray");
            text-decoration: none;
            font-size: 1.056em;
            white-space: nowrap;
            display: block;


            &.active {
              color: color("red") !important;
            }

            &.active-trail {
              color: color("red");
            }

          }
        }
      }

      /* Hover styles for drop down, kept separate for convenience */
      .dropdown {
        position: relative;
        display: inline-block;
        padding-top: 0.556em;
        z-index: 5;

        @include media($bp-smdesktop) {
          padding-top: 0;
          padding-bottom: 1em;
        }

        @include media($bp-desktop) {
          padding-bottom: 2.1em;
        }

        ul.menu {
          position: absolute;
          display: none;
          margin: 0;
          list-style: none;
          padding: 0;
          background-color: color("white");
          border: 1px solid color("red");
        }

        &:hover {
          ul.menu {
            display: block;
          }
        }

        li a {
          display: inline-block;
          padding: 0.4em;
          text-decoration: none;
          background-color: transparent;
          color: color("lightgray");
          white-space: nowrap;
          border-bottom: 1px solid color("lightgray");
          color: color("darkgray");
          width: 100%;
          font-size: 1em;
          &:hover {
            color: color("white");
            background-color: color("red");
          }
        }

      }
    }
  }

  li {
    .mobile-only,
    &.mobile-only {
      @include media ($bp-smdesktop) {
        display: none;
        visibility: hidden;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  .no-touchevents ul.menu li .menu li a:hover,
  .no-touchevents ul.menu li ul li a:hover {
    color: color("darkgray");
    opacity: 0.8;
  }

  .no-touchevents ul.menu .dropdown ul.menu:hover {
    ul.menu {
      display: block;
    }
  }

  .no-touchevents ul.menu .dropdown a.dropdown-toggle:hover {
    color: color("red");
  }
}

.responsive-menu-toggle-icon .label {
  color: transparent;

  @include media($bp-smdesktop) {
    display: none;
    visibility: hidden;
  }
}

.responsive-menu-toggle-icon {


  @include media($bp-landscape) {
    padding-right: 0;
  }

  @include media($bp-smdesktop) {
    display: none;
    visibility: hidden;
  }
}

.responsive-menu-toggle-icon .label {
  display: none;
}

.responsive-menu-toggle-icon .icon,
.responsive-menu-toggle-icon .icon:before,
.responsive-menu-toggle-icon .icon:after {
  height: 0.15em;
  width: 1em;
  background: color("darkgray") !important;
  position: relative;
  display: block;
  content: '';
}

.responsive-menu-toggle-icon .icon:before {
  top: -.4em;
}

.responsive-menu-toggle-icon .icon:after {
  bottom: -.25em;
}

#block-responsive-menu-toggle {
  margin: 1.2em 1em 1em 1em;
  float: right;
  text-align: center;
  width: 30%;

  @include media($bp-landscape) {
    margin: 1.5em 0 1.3em 6em;
  }

  @include media($bp-tablet) {
    margin: 2em 0 2em 23em;
    width: 10%;
  }

  @include media($bp-smdesktop) {
    display: none;
  }
}

#block-responsive-menu-horizontal-menu {
  display: none;
}

#off-canvas, .responsive-menu-block ul {
  font-size: .8em;
  z-index: 1000;

  @include media($bp-smdesktop) {
    display: none;
  }
}

// Adjusting the look of the off-canvas menu
.mm-menu.mm-theme-white {
  color: color("darkgray");
  border-color: color("darkgray");
  background-color: color("white");
}

.mm-menu.mm-theme-white .mm-navbar a,
.mm-menu.mm-theme-white .mm-navbar>* {
  color: color("darkgray");
}

.mm-menu.mm-theme-white .mm-listview>li .mm-next:after,
.mm-menu.mm-theme-white .mm-btn:after,
.mm-menu.mm-theme-white .mm-btn:before {
  border-color: color("red");
}

.mm-panels>.mm-panel>.mm-listview {
  margin: 20px -20px 20px -30px;
}


