.search-results {
  li {
    border-bottom: 1px solid color("red");
    width: 80%;
    margin: 0 auto;

    h3 {
      margin-bottom: 0;
      color: color("red");
      font-family: $button-font;
    }

    .search-snippet-info {
      padding-bottom: 1em;
    }
  }
}