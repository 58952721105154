#desktop {
  display: none;
  visibility: hidden;
  
  @include media($bp-tablet) {
    display: block;
    visibility: inherit;

    &.vid-header {
      margin-bottom: -0.5em;

      video {
        width: 100%;
      }
    }
  }
}

#mobile {

  &.vid-mobile {
    margin: 0.3em 0 -0.4em 0;

    img {
      width: 100%;
      height: 25%;
    }
  }
  @include media($bp-tablet) {
    display: none;
    visibility: hidden;
  }
}