.page-our-brands {
  .mobile {
    img {
      min-width: 320px;
      max-width: 768px;
      width: 100%;
      border-top: 1px solid color("red");
      border-bottom: 1px solid color("red");
    }
    @include media($bp-smdesktop) {
      display: none;
      visibility: hidden;
    }
  }
  
  .desktop {
    display: none;
    visibility: hidden;
    
    @include media($bp-smdesktop) {
      display: block;
      visibility: visible;
    }
  }
}
// look of the inner menu for anchors nav
#block-views-brand-landing-block {
  border-top: 1px solid color("red");
  border-bottom: 1px solid color("red");


  @include media($bp-lgdesktop) {
    margin-top: .5em;
  }
}

// view of the anchors nav
.view-brand-landing {
  background-color: color("white");
  

  ul {
    list-style: none;
    margin: 0 auto;
    width: 90%;
    text-align: center;
  }

  .item-list ul li {
    margin-left: 1em;
  }

  li {
    display: inline-block;
    padding: 15px 1px !important;
    margin-bottom: -1px !important;

    a {
      color: color("red");
      font-weight: 900;
      padding: 15px !important;
    }

    a:hover {
      padding: 15px !important;
      color: color("white");
    }
  }

  li:hover {
    background-color: color("red");
  }
}

//anchor alignment
.anchor {
  padding-top: 190px;
}

// Brands listing
.view-brand-landing.view-display-id-page {
  .views-row {
    width: 100%;
    color: color("black");
  }

  img {
    width: 100%;
    height: 25%;

    @include media($bp-smdesktop) {
      width: auto;
      height: 100%;
    }
  }

  h2 {
    margin: .5em;
    text-align: left;

    @include media($bp-smdesktop) {
      margin-left: 1em;
    }
  }

  .views-field-body,
  .views-field-field-view-products {
    margin-left: 1em;

    @include media($bp-smdesktop) {
      margin-left: 0;
    }
  }

  .views-field-field-view-products {
    margin-top: 1em;
    margin-bottom: 1em;

  }

  .brand-info p {
    margin-left: 1em;

    @include media($bp-smdesktop) {
      margin-left: 0;
    }

    &:last-child {
      margin-top: 2em;
      margin-bottom: 3.5em;

      @include media($bp-smdesktop) {
        margin-bottom: 2em;
      }
    }

  }

  .brand-button {
    cursor: pointer;
    color: color("white");
    width: 50%;
    padding: 11px 15px;
    background-color: color("red");
    border: 2px solid color("red");
    border-radius: 24px;
    height: 48px;
    font-weight: 900;
    text-transform: uppercase;
    font-family: $button-font;
  }

  .views-field-field-image img {
    margin-top: 2em;
  }

  @include media($bp-smdesktop) {
    .views-field-field-image img {
      width: 50%;
      margin: 2em auto auto;
    }

    h2 {
      text-align: left;
      margin-left: 0;
      margin-bottom: 0;
      line-height: 1em;
      margin-top: 0;
    }

    // to move odd numbered rows to the left
    .views-row-odd {
      margin: 5em 1em;
      height: 300px;
      clear: both;

      .brand-info {
        float: left;
        width: 43%;
        margin-right: 2em;
        text-align: left;
      }
    }

    // to move even numbered rows to the right
    .views-row-even {
      margin: 5em 1em;
      height: 300px;
      clear: both;

      .brand-info {
        float: right;
        width: 43%;
        margin-left: 2em;
        text-align: left;
      }
    }

    // padding at the bottom
    .views-row-last {
      margin-bottom: 5em;
    }
  }
}

.view-brand-landing.view-display-id-page {
  @include media($bp-desktop) {
    .views-row-even, .views-row-odd {
      min-height: 270px;
    }
  }
}
