.view-search {
  .view-header,
  .view-filters {
    width: 80%;
    margin: auto;
  }
  h1 {
    margin: 0;
  }
  ol {
    padding: 0;
    list-style: none;
    li {
      list-style: none;
    }
  }
  h2 {
    margin-bottom: 0;
    color: #be322c;
    font-family: "Open Sans Condensed",sans-serif;
    font-size: 1.667em;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.944em;
    text-align: left !important;
  }
  .links.inline {
    display: none;
  }
  .node {
    padding-bottom: 1.5em;
    .field-name-body {
      width: 100% !important;
      float: none !important;;
      margin: 0 !important;;
      padding: 0 !important;;
    }
  }

}

body.page-search .view-related-products {
  display: none;
}
body.page-search {
  .node-industry,
  .node-industry-and-service {
    display: block;

  }
}