/* FRONT - EVENTS BLOCK */
.events {
  margin: 0 1em;

  @include media($bp-landscape) {
    margin-bottom: 0.89em;
  }

  @include media($bp-tablet) {
    width: 40%;
    float: left;
  }

  @include media($bp-smdesktop) {
    width: 30%;
  }

  @include media($bp-desktop) {
    width: 22%;
    margin: 0 10px;
  }

  @include media($bp-lgdesktop) {
    width: 20%;

  }

  h4 {
    font-weight: 900;
    color: color("darkgray");
    border-bottom: 1px solid color("lightgray");
    margin: 1em 0 .5em;
  }

  h5 {
    text-transform: capitalize;
    margin: 0;
    font-weight: 700;
  }

  .views-row {
    margin-bottom: 1em;
  }

  .view-footer {
    color: color("red");
    font-weight: 700;

    a {
      font-family: $button-font;
    }
  }
}

/* Events page */
.page-resources-events {
  #content .container {
    max-width: 100%;
  }

  #breadcrumb {
    background-color: color("red");
    margin: 0;
    padding-top: 1em;

    .breadcrumb {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;

      a, .delimiter {
        color: color("white");
      }
    }
  }

  .events-page {
    padding-bottom: 1em;

    .view-header {
      background-color: color("red");
      margin-bottom: 3em;

      h1 {
        max-width: 1416px;
        min-width: 320px;
        margin: 0 auto;
        color: color("white");
      }
    }

    .view-content {
      max-width: 1416px;
      min-width: 320px;
      margin: 2em auto;
    }

    .views-row {
      display: block;
      padding: 2em 1em 4em;
      border-bottom: 1px solid color("lightgray");

      @include media($bp-landscape) {
        margin-bottom: 1em;

      }

      @include media($bp-tablet) {

      }

      @include media($bp-smdesktop) {
        width: 100%;
        height: 200px;
        padding: 2em 1em;
      }

      .events-dates {
        padding-bottom: 1em;
        font-style: italic;
        color: color("darkgray");
        border-bottom: 5px solid color("red");
        width: 50%;
        margin-bottom: 1em;

        #event-days {
          font-size: 1.5em;
          font-style: initial;

          @include media($bp-smdesktop) {
            font-size: 2em;
          }

          .date-display-range {
            padding-top: .5em;
          }
        }

        @include media($bp-landscape) {
          width: 30%;
          float: left;
          border-bottom: none;
          border-right: 5px solid color("red");
          margin-right: 2em;
        }

        @include media($bp-tablet) {
          width: 20%;
        }

        @include media($bp-smdesktop) {
          width: 15%;
        }
      }

      .event-logo {
        padding-bottom: 1em;

        @include media($bp-landscape) {
          height: 110px;
          padding: .5em 0;
          float: left;
          width: 30%;
        }

        @include media($bp-smdesktop) {
          width: 20%;
        }
      }

      .event-local {
        padding-bottom: 1em;
        color: color("darkgray");

        h4 {
          margin: 0;
        }

        p {
          margin: 0;
        }

        @include media($bp-landscape) {
          width: 100%;

          p {
            padding-bottom: .5em;
          }
        }

        @include media($mobile-only){
          clear: both;
        }

        @include media($bp-smdesktop) {
          clear: none;
          width: 35%;
          float: left;

          #booth {
            padding-left: 2em;
          }
        }
      }

      .event-site {
        cursor: pointer;
        color: color("white");
        width: 50%;
        padding: 7px 0 5px;
        background-color: color("red");
        border: 2px solid color("red");
        border-radius: 24px;
        height: 48px;
        float: left;
        text-align: center;
        text-transform: uppercase;
        font-weight: 900;
        font-family: $button-font;

        @include media($bp-landscape) {
          margin: 0 30%;
          width: 40%;
        }

        @include media($bp-tablet) {
          width: 30%;
        }

        @include media($bp-smdesktop) {
          margin: 1em;
          float: left;
          width: 20%;
        }

        @include media($bp-desktop) {
          width: 15%;
        }
      }
    }
  }
}