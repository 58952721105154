/* The Product Finder */
.page-products-product-finder {
  .block-facetapi {
    color: color("darkgray");
    margin: 0 1em;

    h2 {
      border: 1px solid color("lightgray");
      font-size: 1.111em;
      font-weight: 900;
      padding: 0 .5em;

      @include media($bp-smdesktop) {
        margin-left: -.5em;
      }


      &:after {
        content: "\f055";
        color: color("red");
        font-family: FontAwesome;
        float: right;
      }
    }

    &.expanded h2 {
      &:after {
        content: "\f056";
        color: color("red");
        font-family: FontAwesome;
        float: right;
      }
    }

    .item-list {
      ul {
        li {
          list-style: none;
          color: color("darkgray");
          margin: 0 .5em;
        }
      }
      .facetapi-limit-link {
        color: color("red");
        font-weight: 900;
        margin-left: .889em;
      }
    }
  }

  .view-product-finder {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;

    .views-row {
      min-height: 260px;
      width: 50%;
      text-align: center;
      float: left;
      border-top: 1px solid color("lightgray");

      @include media($bp-landscape) {
        width: 40%;
        float: left;
      }

      @include media($bp-midlandscape) {
        width: 50%;
        min-height: 400px;
      }

      @include media($bp-tablet) {
        border: none;
      }

      @include media($bp-smdesktop) {
        width: 280px;
      }

      @include media($bp-lgdesktop) {
        width: 315px;
        height: 300px;
      }

      &.views-row-odd {
        border-right: 1px solid color("lightgray");
        border-bottom: 1px solid color("lightgray");
        padding: 1em;

        @include media($bp-tablet) {
          border: none;
        }
      }

      &.views-row-even {
        border-left: 1px solid color("lightgray");
        border-bottom: 1px solid color("lightgray");
        padding: 1em;

        @include media($bp-tablet) {
          border: none;
        }
      }

      .product-finder-title {
        font-weight: 900;
        color: color("darkgray");
        font-size: 1.111em;

        &:hover {
          color: color("red");
        }

      }

      .product-finder-image {

        img {
          width: 100%;
          height: 100%;

          @include media($bp-smdesktop) {
            width: auto;
            max-height: 210px;
            object-fit: scale-down;
          }
        }
      }
    }

    .item-list {
      float: right;
      width: 100%;
      margin: 2em;

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        li {
          padding: 10px 20px;
          margin: 10px 0;
          border: 1px solid color("lightgray");
          color: color("red");
          font-weight: 900;
          text-transform: uppercase;

          @include media($bp-tablet) {
            margin: 0 1px;
          }

          &.pager-first,
          &.pager-last {
            display: none;
            visibility: hidden;
          }

          &.pager-current {
            background-color: color("red");
            color: color("white");
          }

          &:hover {
            background-color: color("red");
            color: color("white");
          }
        }
      }

      @include media($bp-smdesktop) {
        width: auto;
        float: right;
        margin-left: 25%;
      }
    }
  }
}

/* Product find by title block */
.product-finder-exposed {
  &.desktop {
    display: none;
    visibility: hidden;

    @include media($bp-tablet) {
      display: block;
      visibility: inherit;
    }
  }

  &.mobile {
    margin-left: 1em;
    margin-bottom: 1em;

    @include media($bp-tablet) {
      display: none;
      visibility: hidden;
    }
  }
}
#views-exposed-form-product-finder-page {
  width: 95%;

  .views-exposed-form {
    background-color: color("darkgray");
    color: color("white");
    padding: 1em;

    .form-item-product-finder-1 {
      display: inline;
      float: left;

      &:before {
        content: '\f002';
        font-family: FontAwesome;
        background-color: color("red");
        color: color("white");
        padding: .280em .5em;
        font-size: 1.111em;
        float: right;
      }
    }

    label {
      font-size: 1.556em;
      line-height: 1.23em;
      padding-bottom: .55em;
      padding-right: 1em;
    }

    input {
      width: 84.5%;
      padding: .2em .5em;
    }

    .description {
      font-size: 0.889em;
      padding-top: .55em;
    }

    a {
      text-decoration: underline;
    }
  }
}