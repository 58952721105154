.gray-background {
  background-color: color("lightgray");
  margin-bottom: -.1em;
  padding-top: 1em;
  padding-bottom: 1em;

  .square-button {
    color: color("white");
    text-align: center;

    a {
      border: 2px solid color("white");
      padding: 30px 40px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}