/* Foursquare position and hover actions (LANDING PAGES) */
.square-link .view-content {
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-flow: row wrap;
  align-content: flex-end;
  z-index: -10;
  margin: 3em 0;

  @include media($bp-tablet) {
    //width: 220px;
  }

  @include media($bp-smdesktop) {
    width: auto;
  }
}

.square-link .views-row {
  position: relative;
  background: color("darkgray");
  text-align: center;
  width: 300px;
  height: 290px;

  @include media($bp-landscape) {
    width: 575px;
  }

  .views-field-title {
    padding: 5em 2em 0;
    color: color("white");
    text-transform: uppercase;
    backface-visibility: hidden;
  }

  .views-field-title,
  .views-field-title > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .views-field-title > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    opacity: 0;
  }
}

.foursquare {
   background: linear-gradient(45deg, color("darkgray") 0%, color("lightgray") 40%, color("lightgray") 100%);

   img {
     opacity: .3;
     transition: opacity 0.35s;
     width: 300px;


     @include media($bp-landscape) {
       width: 100%;
     }
   }

   .views-field-title {
     padding: 2em;
   }

   .views-field-title::before {
     position: absolute;
     top: 30px;
     right: 30px;
     bottom: 30px;
     left: 30px;
     border: 1px solid color("white");
     content: '';
     opacity: 1;
     transition: opacity 0.35s, transform 0.35s;
     transform: scale(0);
   }

   a {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
   }
 }

.foursquare:hover {
  h3 {
    transform: translate3d(0, 0, 0);
  }

  .views-field-title::before {
    opacity: 1;
    transform: scale(1);
  }

  .views-field-title {
    background-color: color("red");
    opacity: 0.9;
  }

  img {
    opacity: 0.9;
    background-color: color("red");
  }
}

#about {
  .foursquare {
    background: transparent;
    border: 1px solid color("lightgray");

    .views-field-title {
      padding: 4em 2em;
      color: color("red");

      @include media($bp-smdesktop) {
        padding: 0;
      }

      h3 {
        line-height: 1.3em;

        @include media($bp-smdesktop) {
          padding: 3em;
        }
      }
    }

    .views-field-title::before {
      position: absolute;
      top: 30px;
      right: 30px;
      bottom: 30px;
      left: 30px;
      border: 1px solid color("white");
      content: '';
      opacity: 1;
      transition: opacity 0.35s, transform 0.35s;
      transform: scale(0);
    }

    a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .foursquare:hover {
    h3 {
      transform: translate3d(0, 0, 0);
    }

    .views-field-title::before {
      opacity: 1;
      transform: scale(1);
    }

    .views-field-title {
      background-color: color("red");
      color: color("white");
      opacity: 0.9;
    }
  }
}