/* FRONT NEWS-BLOG-SOCIAL BLOCK */
.news-blog-social {
  margin: 0 1em;

  h4 {
    border-bottom: 1px solid color("lightgray");
    font-weight: 900;
    color: color("darkgray");
    margin: 1em 0 .5em 0;
  }

  h6 {
    font-size: 1em;
    line-height: 1em;
    text-transform: none;
    margin: 0;
  }

  .views-row {
    margin-bottom: 20px;

    .views-field-body .field-content {
      display: none;
    }

    p {
      margin: 0;
    }
  }

  .view-footer {
    a {
      color: color("red");
      //margin-left: 10px;
      font-family: $button-font;
    }

    p {
      margin-top: 0;
    }

  }

  i {
    color: color("red");
    margin: 0 5px;
  }

  @include media($bp-tablet) {
    width: 50%;
    float: left;
    margin-bottom: 0.89em;
  }

  @include media($bp-smdesktop) {
    width: 30%;
  }

  @include media($bp-desktop) {
    width: 38%;
  }

  @include media($bp-lgdesktop) {
    width: 45%;
  }
}
.node-type-article {

  .field-type-datetime {
    margin-left: 1em;

    @include media($bp-smdesktop) {
      margin-left: 490px;
      margin-top: 20px;
    }
  }

  h4 {
    color: color("darkgray");
    text-transform: capitalize;
    margin-top: 0;
    margin-left: 0.6em;

    @include media($bp-smdesktop){
      margin-left: 485px;
      margin-bottom: 10px;
      margin-top: 0;
    }
  }

  .feat-img {
    float: left;
    margin-left: 1em;

    @include media($bp-landscape) {
      width: 50%;
      margin-right: 0;
    }

    @include media($bp-smdesktop) {
      width: initial;
      margin: -13% 15px -12px 15px;
    }

    @include media($bp-desktop) {
      margin-top: -11%;
    }

    @include media($bp-lgdesktop) {
      margin-top: -6%;
    }

    img {
      border: 1px solid color("lightgray");

      @include media($bp-landscape) {
        width: 90%;
      }

      @include media($bp-smdesktop) {
        width: initial;
      }
    }

    i {
      font-size: 1.778em;
      margin: 0 10px;
      color: color("darkgray");
    }

    .fa-facebook-square {
      margin-left: 0;
    }
  }


  #content{
    padding-top: 10px;

    .field-name-body {
      margin-left: 1em;
      padding-right: 12px;

      @include media($bp-smdesktop) {
        margin-left: 485px;
      }
    }
  }

  #footer {
    margin-top: 0; //moves the footer to touch under the mailing list CTA
  }

  // Join the mailing list on news pages
  .article-mailing-list {
    background-color: color("red");
    color: color("white");
    width: 100%;
    margin-bottom: -1em;

    @include media($bp-landscape) {
      margin-bottom: -5px;
    }

    .content {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;
    }

    h2 {
      color: color("white");
      font-weight: 900;
      text-align: left;
      margin-left: 0.5em;
      padding-top: 0.5em;

      @include media($bp-smdesktop) {
        width: 40%;
        margin: 2% 10% 0 5%;
      }

      @include media($bp-desktop) {
        width: 35%;
        margin: 2% 15% 0 10%;
      }
    }

    p {
      text-wrap: wrap;
      margin: 0 1em;

      @include media($bp-smdesktop) {
        width: 40%;
        float: left;
        margin: 0 5% 3% 5%;
      }

      @include media($bp-desktop) {
        width: 35%;
        margin: 0 10% 3% 10%;
      }
    }

    .webform-client-form {
      margin: 1em;

      @include media($bp-smdesktop) {
        float: left;
        width: 45%;
        margin: -20px 0 0 0;
      }

      .form-item, .form-actions {
        display: inline;
      }

      .form-item input {
        width: 75%;
        height: 60px;
        color: color("black");

        @include media($bp-landscape) {
          width: 60%;
        }
      }

      .webform-submit.button-primary {
        background-color: color("red");
        color: color("white");
        font-family: $button-font;
        text-transform: uppercase;
        border: 2px solid color("white");
        margin-left: -.3em;
        padding: .60em 0 .59em 0;
        height: 60px;
        width: 25%;
      }
    }
  }
}

/* News landing page */
.page-resources-news {
  #content .container {
    max-width: 100%;
  }

  #breadcrumb {
    background-color: color("red");
    margin: 0;
    padding: 1em;

    .breadcrumb {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;

      a, .delimiter {
        color: color("white");
      }
    }

    @include media($bp-landscape) {
      margin-top: .5em;
    }

    @include media($bp-smdesktop) {
      margin-top: 0;
      padding: 1em 0 0 0;
    }
  }

  .view-header {
    background-color: color("red");
    margin-bottom: 3em;

    h1 {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;
      color: color("white");
      padding: 0 .5em;
    }
  }

  .view-filters {
    max-width: 1416px;
    min-width: 320px;
    margin: 0 auto;
    padding: 0 .89em;
  }

  .views-exposed-widget {
    margin: 0;
    padding: 0;

    .form-item {
      margin-right: 0;
    }

    select {
      padding: 5px 10px;
      margin-right: 0;
      font-weight: 900;
      font-family: $button-font;
      color: color("darkgray");
    }
  }

  .view-content {
    max-width: 1416px;
    min-width: 320px;
    margin: .8em auto;

    @include media($bp-tablet) {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: space-between;
    }

    .field-content{
      overflow: hidden;
      position: relative;
      display: flex;

      img{
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .views-row {
    width: 90%;
    margin: 1em 5%;
    border: 1px solid color("lightgray");

    @include media($bp-landscape) {
      width: 70%;
      margin: 0 auto 1em auto;
    }

    @include media($bp-tablet) {
      width: 48%;
      margin: 0 1% 1em 1%;
    }

    @include media($bp-smdesktop) {
      width: 31%;
    }

    img {
      width: 100%;
      max-height: 250px;
    }

    .views-field-field-date {
      padding: 1em;
      color: color("darkgray");
    }

    .views-field-title {
      padding: 0 1em;
      margin-bottom: 1em;

      h4 {
        padding: 0;
        text-transform: capitalize;
        color: color("darkgray");
        line-height: 1em;
        margin: 0;
        font-weight: 400;

        a:hover {
          color: color("red");
        }
      }
    }
  }
}