.region-subnav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 2px 0.5em 0 0;

  @include media($bp-desktop) {
    flex-direction: column;
    align-items: flex-end;
  }

  .top_nav_info {
    display: none;

    @include media($bp-smdesktop) {
      order: 1;
      display: block;
      font-size: 0.778em;
      font-weight: 900;
    }

    @include media($bp-desktop) {
      order: 0;
    }

    p {
      margin-top: 0;

      a {
        margin: 0 5px;
        text-align: center;
        font-size: 12px;
        line-height: 0.778em;
        color: color("red");
      }
    }
  }
}
