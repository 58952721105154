.node-type-faq {
  #content .container {
    max-width: 100%;
  }

  #breadcrumb {
    background-color: color("red");
    margin: 0;
    padding: 1em 1em 0 1em;

    @include media($bp-landscape) {
      padding-top: 2em;
    }

    @include media($bp-tablet) {
      padding-top: 1em;
    }

    .breadcrumb {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;

      a, .delimiter {
        color: color("white");
      }
    }
  }

  .faq {
    #faq-header {
      background-color: color("red");
      padding: 0 1em;
      margin-bottom: 3em;

      .team-header {
        max-width: 1416px;
        min-width: 320px;
        margin: 0 auto;
        color: color("white");
      }
    }

    p {
      max-width: 1416px;
      min-width: 320px;
      margin: 2em 1em;

      @include media($bp-desktop) {
        margin: 2em;
      }

      @include media($bp-lgdesktop) {
        margin: 2em auto;
      }
    }
  }

  .field-name-field-questions {
    max-width: 1416px;
    min-width: 320px;
    margin: 2em .2em;

    @include media($bp-tablet) {
      margin: 2em 1em;
    }

    @include media($bp-desktop) {
      margin: 2em auto;
    }

    .faqfield-question, .faqfield-answer {
      border: none;
    }

    .faqfield-question {
      border-right: none;
      border-left: none;
      border-top: 1px solid color("lightgray");
      border-bottom: 1px solid color("lightgray");

      a {
        color: color("red");
        font-weight: 900;
        font-size: .8em;
        text-transform: none;

        @include media($bp-tablet) {
          font-size: 1.2em;
        }

        @include media($bp-smdesktop) {
          font-size: 1.35em;
        }

        @include media($bp-desktop) {
          font-size: 1.556em;
        }
      }

      &.ui-accordion-header {
        padding-left: 0;
        //margin-right: 50px;

        a {
          &:after {
            content: '\f078';
            font-family: FontAwesome;
            color: color("red");
            float: right;
            position: absolute;
            padding-right: 1%;
            right: 0;
          }
        }
      }

      &.ui-accordion-header-active {
        a {
          &:after {
            content: '\f077' !important;
            font-family: FontAwesome;
            color: color("darkgray");
            position: absolute;
            padding-right: 1%;
            right: 0;
          }
        }
      }

      &.ui-accordion-header-active {
        a {
          color: color("darkgray");
        }
      }

      .ui-accordion-header-icon {
        display: none;
      }
    }

    .ui-accordion-header {
      background: transparent !important;
    }
  }
}