/* FRONT Locate a Dealer */
.dealer-locator {
  order: -1;
  width: 100%;
  margin: 0;
  padding: 10px;
  text-align: center;
  color: color("white");

  @include media($bp-smdesktop) {
    order: 0;
  }

  #locator {
    padding-top: 10px;
    padding-bottom: 8.8px;

    @include media($bp-smdesktop) {
      height: 100%;
    }

    h2 {
      margin: 0;
      text-transform: none;
      font-weight: 900;
      line-height: 1em;

      @include media($bp-smdesktop) {
        font-size: 2em;
      }

      @include media($bp-desktop) {
        line-height: 1.5em;
      }

      @include media($bp-lgdesktop) {
        font-size: 2.222em;
      }
    }

    p {
      padding: 0 15px;
      margin-top: 0;
      margin-bottom: 1.5em;
      font-weight: 900;
    }

    .fa-search {
      &:before {
        color: color("red");
        //padding-right: 16px;
        background-color: color("white");
        margin-left: -60px;
        border: 2px solid color("white");
        font-size: 1.5em;
        //height: 100%;
      }
    }

    input {
      height: 50px;
      border: 2px solid color("white");
      width: 65%;

      &::placeholder {
        color: color("red");
        text-indent: 5px;
      }

      &:focus {
        outline: none;
      }
    }
  }

  /* Drop down for the Locate a Dealer */
  .dealer-search p {
    margin: 19px 20px;
    background-color: color("white");
    color: color("red");
    height: 35px;
    box-shadow: 1px 1px 15px color("darkgray");
    font-weight: 900;
  }

  .dealer-search input {
    padding: .2em;
  }

  .content {
    height: 100%;
  }

  @include media($bp-smdesktop) {
    flex: 1 28%;
    margin: 0;
    padding: 0;
  }
}

.contact-dealer {
  margin-bottom: 1em;
}

.dealer-locator-full {
  color: color("white");

  #locator {
    padding-top: 15px;
    padding-left: 1em;
    padding-bottom: 1em;
    margin-bottom: -1em;

    @include media($bp-tablet) {
      height: 200px;
      padding-top: 35px;
      padding-left: 0;
    }

    h2 {
      margin: 0;
      text-transform: none;
      font-weight: 900;
    }

    p {
      margin: 0;
    }

    #info-locator {
      @include media($bp-tablet) {
        width: 50%;
        float: left;
        padding-left: 5em;
      }
    }

    .form-row {
      @include media($bp-tablet) {
        width: 50%;
        float: right;
      }
    }

    .fa-search {
      &:before {
        color: color("red");
        //padding: 8.45px 14px 12.5px;
        background-color: color("white");
        margin-top: 40px;
        margin-left: -50px;
        border: 1px solid color("white");
        font-size: 1.5em;

      }
    }

    input {
      height: 50px;
      margin-top: 20px;
      border: 2px solid color("white");
      width: 80%;
      font-weight: 900;
      font-size: 1.111em;

      &::placeholder {
        color: color("red");
        text-indent: 5px;

        @include media($bp-tablet) {
          width: 300px;
          margin-top: 40px;
        }
      }
    }
  }
}
