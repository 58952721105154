/* Front Support box */
.support-block {
  margin-top: 0.89em;


  #support {
    background: url('../../img/front-support-background.jpg') top no-repeat;
    background-size: cover;
    color: color("white");
    text-align: center;
    padding: 10px 0;
    width: 100%;

    p {
      padding: 0 20px;
    }

    button {
      margin-bottom: .9em;
      padding-top: 0;
      padding-bottom: 0;
      font-family: $button-font;
      text-transform: uppercase;
    }

    h2 {
      color: color("white");
      padding-left: 15px;
      margin-bottom: 10px;
      font-weight: 900;
    }

    @include media($bp-desktop) {
      padding: 10px 0 70px 0;
    }

  }

  @include media($bp-smdesktop) {
    width: 25%;
    float: left;
    margin-top: 2em;
    margin-left: 3em;
  }

}