#footer {
  background-color: color("darkgray");
  color: color("white");
  display: block;
  .region-footer-top {
    .block {
      position: relative;
      z-index: 1;
    }
  }
  .block-menu-block {
    width: 100%;

    ul {
      text-align: center;
      padding-left: 0;
      padding-top: 0.893em;
    }

    .leaf,
    li.expanded {
      list-style: none;
      font-weight: 900;

      @include media($bp-lgdesktop) {
        float: left;
        width: 45%;
      }
    }

    li .menu li {
      line-height: 1em;
      font-size: .8em;
      font-weight: 400;
    }

    li .last {
      @include media($bp-lgdesktop) {
        width: 45%;
        float: right;
      }
    }

    li .menu .last {
      padding-bottom: 20px;
    }

    .menu ul {
      padding-top: 0;
    }

    .menu ul li {
      @include media($bp-lgdesktop) {
        float: left;
        width: 100%;
        text-align: left;
        margin-left: 35px;
      }
    }

    a {
      color: color("white");
      text-decoration: none;
    }

    @include media($bp-landscape) {
      width: 50%;
      float: left;
    }

    @include media($bp-tablet) {
      width: 30%;
    }

    @include media($bp-desktop) {
      width: 20%;
      padding-top: 0;
    }

    @include media($bp-lgdesktop) {
      display: inline;
      width: 25%;
    }
  }
}

/* TOP FOOTER - CONNECT SOCIAL MEDIA */
.social-media-connect {
  width: 100%;
  text-align: center;
  margin: 2em 0;

  h3 {
    font-size: .8em;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  i {
    padding: 5px;
    margin: 0 5px;
    background-color: color("white");
    color: color("darkgray");
  }

  .fa-facebook {
    padding: 5px 10px;
  }

  @include media($bp-landscape) {
    width: 50%;
    height: 195px;
    float: right;
  }

  @include media($bp-tablet) {
    width: 30%;
    text-align: left;
    padding-left: 0.5em;
    height: auto;
    float: left;
    margin: auto;
  }

  @include media($bp-smdesktop) {
    padding-left: 1.333em;
    width: 25%;
    margin-top: 1em;
  }

  @include media($bp-desktop) {
    width: 20%;
  }

  @include media($bp-lgdesktop) {
    margin-top: 20px;
  }
}

/* TOP FOOTER - JOIN MAILING LIST */
.mailing-list {
  width: 100%;
  padding-left: 1.333em;
  padding-right: 1.333em;
  text-align: center;
  margin: 3em 0;

  h2 {
    font-size: .8em;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  .form, input {
    padding: .2em;
    font-size: .8em;
    width: 60%;
  }

  .form-item, .form-actions {
    display: inline;
  }

  /* SUBMIT BUTTON */
  .form-actions .webform-submit {
    background-color: color("red");
    color: color("white");
    width: 25%;
    text-transform: uppercase;
    font-weight: 900;
    font-family: $button-font;
    border: 2px solid color("red");

    @include media($bp-landscape) {
      width: 35%;
    }

    @include media($bp-desktop) {
      width: 20%;
    }
  }

  @include media($bp-landscape) {
    width: 45%;
    float: left;
  }

  @include media($bp-tablet) {
    width: 35%;
    padding: 0;
  }

  @include media($bp-smdesktop) {
    width: 25%;
    margin-top: 1em !important;
  }

  @include media($bp-desktop) {
    width: 30%;
    margin-top: 3em;
  }

  @include media($bp-lgdesktop) {
    margin-top: 20px;
    text-align: left;
    width: 25%;
  }
}

/* Languages */
#block-gtranslate-gtranslate {
  text-align: center;
  padding-left: 1.333em;
  padding-right: 1.333em;
  width: 100%;

  h2 {
    font-size: .8em;
    text-transform: capitalize;

    @include media($bp-landscape) {
      margin-bottom: 0;
    }
  }

  select {
    background-color: color("white");
    color: color("red");
    text-align: center;
    padding: .5em;
    font-size: .8em;
    position: relative;

    @include media($bp-desktop) {
      width: 80%;
    }
  }

  @include media($bp-landscape) {
    width: 45%;
    float: right;
    margin-top: -1.5em;
  }

  @include media($bp-tablet) {
    width: 30%;
    text-align: left;
    margin-top: 0;
    float: left;
  }

  @include media($bp-smdesktop) {
    width: 25%;
  }

  @include media($bp-desktop) {
    width: 30%;
  }

  @include media($bp-lgdesktop) {
    margin-top: 0;
  }
}

/* COPYRIGHT */
.copyright {
  text-align: center;
  width: 100%;

  &.contextual-links-region {
    position: inherit;
  }

  #copy {

    @include media($bp-landscape) {
      clear: both;
      width: 90%;
      padding-top: 1.5em;

    }

    @include media($bp-tablet) {
      width: 75%;
    }

    @include media($bp-smdesktop) {
      width: 90%;
    }

    @include media($bp-desktop) {
      width: auto;
    }
  }

  #ies {
    height: 100px;

    @include media($bp-tablet) {
      float: right;
      z-index: 10;
      width: 20%;
      background-color: color("darkgray");
      margin-top: -100px;
    }

    @include media($bp-lgdesktop) {
      width: 10%;
    }
  }

  p {
    font-size: 0.778em;
    padding-bottom: 15px;
  }

  img {
    margin-bottom: -0.556em;
    width: 144px;
  }

  a {
    font-weight: 900;
    text-decoration: underline;
  }
}

/** Constant Contact Signup **/
div.ctct-form-embed {
  div.ctct-form-defaults {
    h2.ctct-form-header {
      font: inherit !important;
      font-family: Lato,sans-serif !important;
      font-size: 14.4px !important;
      font-weight: bold !important;;
      text-transform: capitalize;
      margin-bottom: 0;
    }
  }
}
.ctct-form-embed.form_0 .ctct-form-defaults .ctct-form-text, .ctct-form-embed.form_0 .ctct-form-custom .ctct-form-label, .ctct-form-embed.form_0 .ctct-form-custom .ctct-form-listname, .ctct-form-embed.form_0 .ctct-form-custom .ctct-form-lists-legend, .ctct-form-embed.form_0 .ctct-form-custom .ctct-form-date-slash, .ctct-form-embed.form_0 .ctct-form-defaults .ctct-form-footer, .ctct-form-embed.form_0 .ctct-form-defaults .ctct-form-footer .ctct-form-footer-link {
  color: #ffffff !important;
}
div.ctct-form-embed form.ctct-form-custom input.ctct-form-element {
  padding: 5px 10px !important;
  height: auto !important;
  border-radius: 0 !important;
}
#block-block-30 button {
  margin-bottom: .9em !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-family: "Open Sans Condensed",sans-serif !important;
  text-transform: uppercase !important;
  padding: 5px 0 !important;
  border-radius: 24px;
}
#block-block-30 {
  .ctct-form-footer {
    display: none;
  }
}
.g-recaptcha {
  display: none;
}
#block-block-30 label {
  font-size: 14.4px !important;
  font-weight: normal !important;;
}
#block-block-30 input {

}
@media screen and (min-width: 550px) {
  #block-block-30 {
    width: 45%;
    float: left;
  }
}
@media screen and (min-width: 769px) {
  #block-block-30 {
    width: 35%;
    padding: 0;
  }
}

@media screen and (min-width: 880px) {
  #block-block-30 {
    width: 25%;
    margin-top: 0.7em !important;
  }
}
