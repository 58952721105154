#main-wrapper {
  padding-top: 4.5em;

  @include media($bp-landscape) {
    padding-top: 5em;
  }

  @include media($bp-smdesktop) {
    padding-top: 4em;
  }
}
.region-content {

  h2 {
    text-align: center;
    margin-bottom: 0;
  }
}

/* Breadcrumbs - Yum */
#breadcrumb {
  margin: 1em 0 0 1em;

  a, span {
    font-size: 14px;
    color: color("red");
  }

  span.last a {
    color: color("lightgray");
  }
}

.basic-page-margin {
  margin: 0 1em 1em 1em;

  h1 {
    color: color("darkgray");
  }
}

.page-node-2502 {
  .location-block {
    .spec-location {
      h3 {
        margin-left: 1em;
        margin-bottom: 0;

        @include media($bp-smdesktop) {
          margin-left: 0;
        }
      }

      h4 {
        margin-top: 0;
        margin-right: .5em;
        margin-left: .5em;
      }
    }
  }
}

.front .region-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media($bp-smdesktop) {
    margin-top: 2em;
  }
}
