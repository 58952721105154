#block-views-banner-images-block {
  z-index: -5;
  position: relative;
}

.view-banner-images {
  .views-field-body {
    margin: 0 auto;
    width: 95%;
    position: relative;
    padding: 2.5em 0 0 0;

    .field-content {
      background-color: color("white");
      padding: 10px 20px;
      margin-bottom: 1em;
      opacity: .9;

      @include media($bp-landscape) {
        margin-top: 1.5em;
      }

      @include media($bp-tablet) {
        margin: 1em auto;
        width: 65%;
      }

      @include media($bp-smdesktop) {
        width: 60%;
        margin: 2em auto;
        padding-bottom: 2%;
      }

      @include media($bp-lgdesktop) {
        width: 45%;
        margin: 3.8em auto;
      }
    }

    h1 {
      color: color("darkgray");
      text-transform: capitalize;
      font-weight: 900;
      text-align: center;
      margin: 20px 0;
    }

    p {
      font-size: 0.889em;
      line-height: 1.389em;

      @include media($bp-desktop) {
        font-size: 1em;
      }
    }

    @include media($bp-landscape) {
      padding: 40px 30px 0;
      margin-bottom: -.1em;
    }

    @include media($bp-smdesktop) {
      padding: 10px 30px;
    }
  }

  .views-field-field-image {
    position: absolute;
    margin-top: 20px;

    @include media($bp-landscape) {
      margin-top: 0;
    }

    @include media($bp-smdesktop) {
      width: auto;
    }

    @include media($bp-extralgdesk) {
      width: 100%;
    }

    img {
      @include media($bp-smdesktop) {
        width: auto;
      }

      @include media($bp-extralgdesk) {
        width: 100%;
      }
    }
  }
}

// Fix for the Our Brands & Resources page
.page-our-brands {

  #block-views-brand-landing-block {
    margin-top: 0;
  }

  .view-banner-images {
    height: 345px;

    .views-field-body {
      padding: 3em 0 1em;

      @include media($bp-tablet) {
        padding-top: 3.4em;
      }

      @include media($bp-desktop) {
        padding-top: 3em;
        width: 85%;
      }

      @include media($bp-lgdesktop) {
        padding: 0.69em 0;
      }
    }
  }
}

.page-resources {
  .view-banner-images {
    .views-field-body {
      padding: 3em 0 1em;

      @include media($bp-tablet) {
        padding-top: 3.3em;
      }

      @include media($bp-lgdesktop) {
        padding: 0.69em 0;
      }


      .field-content {
        margin-bottom: 4.5em;

        @include media($bp-desktop) {
          margin-bottom: 3.7em;
        }

        @include media($bp-lgdesktop) {
          margin-bottom: 4.5em;
        }
      }
    }
  }
}

// Fix for the Industry pages
.page-industries {
  .view-banner-images {
    .views-field-body {
      padding: 3.5em 0 1em;

      .field-content {
        margin-bottom: 3.5em;
      }
    }

  }
}

// Fix for the Support pages
.page-support {
  .view-banner-images {
    .views-field-body {
      padding: 6em 0 1em;

      @include media($bp-smdesktop) {
        padding: 3em 0 1em;
      }

      @include media($bp-desktop) {
        padding: 1em 0;
      }
    }
  }
}

.page-node-2502 {
  .view-banner-images {
    .views-field-body {
      .field-content {
        background-color: transparent;
        color: transparent;
      }
    }
  }
}

// Fix for the about & Who we Serve page
.page-node-2, .page-services {
  .view-banner-images {
    .views-field-body {
      .field-content {
        margin-top: 2em;
        margin-bottom: 7.5em;

        @include media($bp-midlandscape) {
          margin-top: 2.2em;
          margin-bottom: 8em;
          width: 75%;
        }

        @include media($bp-smdesktop) {
          width: 45%;
        }

      }
    }
  }
}