/* Front Who We Serve */
.who-we-serve img.mobile { // Image above section on mobile ONLY
  width: 100%;

  @include media($bp-tablet) {
    display: none;
  }
}

// Section styles
.who-we-serve {

  @include media($bp-landscape) {
    margin: 0 45px;
  }

  @include media($bp-tablet) {
    background: color("darkgray") url('../../img/who-we-serve-bkg-sm.png') bottom no-repeat;
    background-size: 100%;
    color: color("white");
    text-align: center;
    margin: 0 auto;
  }

  @include media($bp-smdesktop) {
    background: url('../../img/who-we-serve-bkg.jpg');
    background-size: cover;
  }

  .content {
    max-width: 1416px;
    margin: 0 auto;
  }

  h2 {
    padding: 10px;
    border-bottom: 1px solid color("lightgray");
    color: color("darkgray");
    font-weight: bold;
    margin: 0;

    @include media($bp-tablet) {
      color: color("white");
      border-bottom: none;
      padding: 20px 0;
    }

    @include media($bp-smdesktop) {
      padding: 0;
    }
  }

  .statement { // Above the sections
    padding-bottom: 25px;
    text-align: center;
    padding-left: 1px;
    padding-right: 1px;

    @include media($bp-tablet) {
      margin: 1% 9%;

    }

    @include media($bp-smdesktop) {
      margin: 0 9%;
    }
  }

  .services-group {
    @include media($bp-tablet) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .service { // sections
    overflow: hidden;
    border-top: 1px solid color("lightgray");

    @include media($bp-tablet) {
      overflow: visible;
      text-align: center;
      margin: 0 auto;
      height: 230px;
      padding: 30px;
      font-size: 0.8em;
      background-color: transparent;
      border-top: none;

      i {
        color: color("white");
        margin: 0 5px;
      }

      p, button {
        color: color("red");
        background-color: transparent;
        border: none;
      }

      button {
        i {
          display: none;
        }
      }
    }

    @include media($bp-smdesktop) {
      padding: 19px;
    }

    @include media($bp-desktop) {
      padding: 30px;
    }

    @include media($bp-landscape) {
      button {
        width: 30%;
      }
    }

    @include media($bp-tablet) {
      width: 48%;

      button {
        width: 70%;
      }
    }

    @include media($bp-smdesktop) {
      width: 23%;
      height: 240px;

      button {
        width: 80%;
      }
    }
  }

  .service:last-child {
    border-bottom: 1px solid color("lightgray");
    margin-bottom: 20px;

    @include media($bp-tablet) {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;

    @include media($bp-tablet) {
      display: none;
    }
  }

  // that which controls the tabs
  label,
  h4 {
    position: relative;
    display: block;
    padding: 0.5em 0 0.5em 0.3em;
    font-weight: bold;
    line-height: 1.111em;
    margin: 0;
    cursor: pointer;
    color: color("red");
    text-transform: capitalize;

    @include media($bp-tablet) {
      color: color("white");
    }

    @include media($bp-smdesktop) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  i.fa {
    display: none;

    @include media($bp-tablet) {
      display: inline-block;
    }
  }

  .tab-content { // content to be shown when clicked
    max-height: 0;
    overflow: hidden;
    transition: max-height .35s;

    @include media($bp-tablet) {
      overflow: visible;
      max-height: 100%;
    }
  }

  .tab-content p {
    margin: 0 0.8em;
    color: color("darkgray");

    @include media($bp-tablet) {
      color: transparent;
    }

    @include media($bp-smdesktop) {
      margin: 0;
    }
  }

  .tab-content button {
    margin: 1em;
    padding: 0;

    @include media($bp-tablet) {
      color: transparent;
    }
  }

  input:checked ~ .tab-content {
    max-height: 10em;

    @include media($bp-tablet) {
      max-height: 100%;
    }
  }

  label::after, h4::after {
    position: absolute;
    right: 0;
    top: 1.3em;
    display: block;
    width: 3em;
    height: 2em;
    text-align: center;
    transition: all .35s;

    @include media($bp-tablet) {
      display: none;
    }
  }

  input[type=radio] + label::after,
  input[type=radio] + h4::after {
    content: "\f107";
    font-family: FontAwesome;

    @include media($bp-tablet) {
      display: none;
    }
  }
  input[type=radio]:checked + label::after,
  input[type=radio]:checked + h4::after {
    transform: rotateX(180deg);
    top: .7em;

    @include media($bp-tablet) {
      display: none;
    }
  }

}

// Hover for the tablet and desktop versions
.service:hover {
  @include media($bp-tablet) {
    background-color: color("white");
    color: color("red");

    h4 {
      color: color("red");
    }

    button {
      i {
        display: inline;
      }
    }

    i {
      display: none;
    }

    p {
      color: color("darkgray");
      background-color: color("white");
    }

    button {
      border: none;
      background-color: color("red");
      color: color("white");
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1em;
    }
  }
}