.page-resources,
.page-support {
  .view-resources-and-support {
    margin-top: 15%;

    @include media($bp-tablet) {
      margin-top: 5%;
    }

    @include media($bp-desktop) {
      margin-top: 4em;
    }

    .view-content {
      display: flex;
      align-items: space-between;
      justify-content: space-around;
      flex-flow: row wrap;
      align-content: flex-end;
      position: relative;
    }

    .views-row {
      margin: 40px 0;
      text-align: center;

      @include media($bp-tablet) {
        width: 50%;
        padding: 0 .5em;
      }

      @include media($bp-smdesktop) {
        width: 30%;
      }

      @include media($bp-desktop) {
        width: 25%;
      }

      .views-field-title {
        h3 {
          margin: 0;
          color: color("red");
          line-height: 1.3em;
          font-size: 1.4em;

          @include media($bp-extralgdesk) {
            font-size: 1.667em;
          }

          a:hover {
            img {
              opacity: .5;
            }
          }
        }
      }

      .views-field-body p {
        color: color("darkgray");
        margin-top: 0;
      }
    }
  }
}


