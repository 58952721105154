@include media($mobile-only) {
  $toggle-height: 40px;
  $toggle-dropdown-max-height: 800px;
  $toggle-border-thickness: 2px;

  #sidebar-first {
    background: rgba(0, 0, 0, 0.05);
    position: relative;
    margin-top: $toggle-height;

    .product-filter-toggle {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: $toggle-height;
      width: 100%;
      color: color("red");
      top: -$toggle-height;
      left: 0;
      transition: all 0.5s;
      padding-right: 12px;
      border-top: $toggle-border-thickness solid color("red");
      border-bottom: $toggle-border-thickness solid color("red");

      .hidden, .visible{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
      }
      .arrow{
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s;
      }
      .visible{
        display: none;
      }

      &:hover{
        cursor: pointer;
      }
    }

    .section {
      max-height: 0;
      transition: all 0.5s;
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;

      .region-sidebar-left {
        padding: 1em 0;
      }
    }

    #block-views-exp-product-finder-page, #block-block-21 {
      display: none;
    }

    &.active{
      .product-filter-toggle {
        .hidden{
          display: none;
        }
        .visible{
          display: flex;
        }
        .arrow{
          transform: rotate(90deg);
        }
      }
      .section{
        max-height: $toggle-dropdown-max-height;
        overflow: auto;
        border-bottom: $toggle-border-thickness solid color("red");
      }
    }
  }

}

@include media($bp-tablet){
  #sidebar-first {

    .product-filter-toggle {
      display: none;
    }
  }
}