.node-type-products {
  .region-content {
    margin: 1em;

    .product-title-display {
      text-align: center;

      h1 {
        text-transform: capitalize;
        line-height: 1.334em;
        color: color("darkgray");
      }

      @include media($bp-landscape) {
        float: right;
        width: 50%;
        text-align: left;
      }

      @include media($bp-desktop) {
        width: 60%;
      }
    }

    .field-name-field-image {

      .field-items {
        width: 100%;
        float: left;
        margin: 0 auto;
        display: block;
        position: relative;
        text-align: center;

        @include media($bp-landscape) {
          width: 50%;
          margin: 1em auto;
        }

        @include media($bp-smdesktop) {
          width: 40%;
        }
      }

      .field-item {
        margin: 0 auto;

        &:not(:first-child) img {
          /*cursor: pointer;*/
        }

        &.even,
        &.odd {
          display: block;
          position: relative;
          margin: 0 auto;
          width: 55%;
          text-align: center;

          @include media($bp-smdesktop) {
            width: 35%;
            display: inline-block;
            margin: 0 .5em;
            padding: 0;

          }

          @include media($bp-desktop) {
            width: 25%;
            display: inline-block;
          }

            img {
              max-width: 100%;
              width: 100%;
              height: auto;

              /*border: 1px solid color("lightgray");*/
            }

          &.even:first-child {
            width: auto;
            padding: 1em;
            @include media($bp-tablet) {
              margin: 1em;
              padding: 0;
            }
            img {
              min-width: 70%;
              height: auto;
              float: none;
              margin: 0;
              width: auto;

            }
          }

        }
      }
    }

    .field-name-body {
      @include media($bp-desktop) {
        width: 50%;
        float: left;
      }
    }

    .field-name-field-video {

      .view-content {
        display: flex;
      }

      @include media($bp-desktop) {
        width: 58%;
        float: left;
        margin: 2em 0;
      }

      iframe {
        padding: 0 10px;
      }
    }
  }

  // JM - The product tabs for Specs, Descriptions, and Lit
  .field-group-easy-responsive-tabs-nav-wrapper {
    margin: 4em 0;
    width: 100%;
    flex: 1 100%;
    background-color: color("darkgray");
    clear: both;

    // For the mobile accordions
    .resp-tab-container {
      border: 0.056em solid color("darkgray");
      width: auto;


      @include media($bp-tablet) {
        border: none;
      }

      h2 {
        background: color("darkgray");
        color: color("white");
        font-size: 1.5em !important;


        .resp-arrow {
          border-color: color("white") transparent transparent transparent;
          margin-top: .89em;
        }
      }
    }

    .resp-tab-active {
      background: color("red") !important;
      color: color("white");


      .resp-arrow {
        border-color: transparent transparent color("white") transparent;
      }
    }

    .resp-arrow {
      margin-top: 0.6em;
    }

  }

    // for tablet and higher
    .field-group-easy-responsive-tabs-processed {
      border: none;
      color: color("darkgray");
      position: relative;


      .resp-tab-content {
        @include media($bp-tablet) {
          border: none;
          font-size: 0.889em;
          line-height: 1.222em;
        }
      }

      ul.resp-tabs-list {
        display: block!important;
        li {
          display: none;
          height: 2.2em;
          line-height: 1.5em;
          padding: 0.722em 2em;
        }
      }

      @include media($bp-tablet) {
        ul.resp-tabs-list {
          display: block;
          li {
            display: inline-block;
          }
        }


      }

      ul.resp-tabs-list li {
        margin-right: -1px;
        padding-top: .4em;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        width: 17%;
        background-color: color("darkgray");
        color: color("white");
        font-family: $button-font;
        font-size: 16px;

        a {
          background-color: color("red");
          color: color("white");
        }
      }

      ul.resp-tabs-list li.resp-tab-active {
        background-color: color("red");
        color: color("white");
        border: none;
        padding: 0.4em 5em!important;

        // The funny little arrow for the active tab
        &:before {
          content: "";
          border-style: solid;
          position: absolute;
          margin-top: 1.5em;
          font-size: 1.2em;
          margin-left: 1.3%;
          border-width: 0.7em 1em 0 1em;
          border-color: color("red") transparent transparent transparent;
        }
      }

      // the specifications tab arrow placement
      ul.resp-tabs-list li.resp-tab-active {
        &:first-child {
          &:before {
            margin-left: .89%;
          }
        }

        &:nth-child(2) {
          &:before {
            margin-left: 1.5%;
          }
        }
      }
    }
}

.related-products {
  width: 100%;
  display: flex;
  justify-content: center;

  .content {
    width: 100%;
  }

  h2 {
    text-align: center;
    font-weight: 900;
    color: color("darkgray");

    @include media($bp-desktop) {
      margin-bottom: 1em;
    }
  }

  .view-content {
    .views-row {
      text-align: center;
      margin-bottom: 3em;

      @include media($bp-tablet) {
        width: 45%;
        float: left;
        height: 450px;
      }

      @include media($bp-smdesktop) {
        width: 23%;
        height: auto;
        margin: 0 1% 5em;
      }

      .views-field-field-image {
        min-height: 230px;
      }

      img {
        width: 60%;
        height: auto;

        @include media($bp-smdesktop) {
          width: auto;
          height: 245px;
        }
      }

      p {
        margin: 0;
        font-family: $button-font;
        color: color("red");
        text-align: center;
        line-height: 1.5em;
        width: 90%;
      }

    }
  }
}

// Product tables


.node-type-products {
  .messages.error {
    display: none;
  }

  .node-products {
    .view-product-table-assignment {
      .table-caption {
        width: 100%;

        h5 {
          margin: 1.389em .5em .5em;
          text-align: center;
        }
      }
      .node-product-table {
        padding-top: 0;
      }

      .table-container {
        position: relative;

        a.tablesaw-nav-btn {
          background-color: color("red");
          color: color("white");

          &.right:before,
          &.left:before {
            border-right-color: color("white");
            border-left-color: color("white");
          }
        }

        table.two-row {
          width: auto;
          margin: 0 0 25px 0 !important;
          padding: 0 !important;

          thead {
            width: 120%;

            @include media($bp-tablet) {
              width: 50%;
            }
          }

          th {
            padding-right: 1.3em;
          }

          tr {
            width: 100%;
          }

          tbody {
            width: 50%;
            border: 0;
          }

          th, td {
            height: 65px !important;
            line-height: 1.75em;
            border-bottom: .056em solid color("darkgray");

            @include media($bp-tablet) {
              height: 30% !important;
            }

            &:first-child {
              border-top: .056em solid color("darkgray");
            }
          }

        }

        table.tablesaw {

          thead th {
            background-color: color("palegray");
            border-top: 1px solid color("darkgray");
            border-bottom: 3px solid color("lightgray");
            text-align: left;
          }

          .tablesaw-swipe-cellpersist {
            border-right: 2px solid color("lightgray");

            @include media($bp-smdesktop) {
              border-right: none;
            }
          }

          tr {
            border-bottom: 1px solid color("darkgray");
          }
        }
      }
    }
  }
}
.node-products {
  .field-name-field-image {
    .field-item {
      > a {
        cursor: zoom-in;
      }
    }
  }
}