// Change page node on dev site for it to work
.page-node-658 {
  #content .container {
    max-width: 100%;
  }

  #breadcrumb {
    background-color: color("red");
    margin: 0;
    padding: 1em 1em 0 1em;

    .breadcrumb {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;

      a, .delimiter {
        color: color("white");
      }
    }
  }

  #used-equipment {
    .used-header {
      background-color: color("red");
      padding: 0 1em;
      margin-bottom: 3em;

      .team-header {
        max-width: 1416px;
        min-width: 320px;
        margin: 0 auto;
        color: color("white");
        text-transform: capitalize;
      }
    }

    .used-all {
      max-width: 1416px;
      min-width: 320px;
      margin: 2em auto;

      .intro {
        width: 80%;
        text-align: center;
        margin: 0 auto 3em auto;
        color: color("darkgray");

        @include media($bp-smdesktop) {
          width: 60%;
        }

        h1 {
          text-transform: capitalize;
          margin: 0;
        }

        p {
          margin-top: 0;
        }
      }

      #used-items {
        width: 95%;
        margin-left: .5em;
        text-align: center;

        @include media($bp-landscape) {
          margin: 0 auto;
          width: 60%;
        }

        @include media($bp-tablet) {
          width: 100%;
        }
      }

      .used {
        @include media($bp-tablet) {
          width: 45%;
          display: inline-flex;
          flex-wrap: wrap;
          margin: 0 .5em;
          a {
            display: block;
            width: 100%;
          }
          img {
            height: 456px;
            min-width: 100%;
          }

          @include media($bp-smdesktop) {
            width: 45%;
          }

          @include media($bp-lgdesktop) {
            width: 38%;
          }

          &:first-child {
            @include media($bp-smdesktop) {

            }
            @include media($bp-lgdesktop) {

            }
          }
        }

        h3 {
          color: color("white");
          z-index: 10;
          text-align: center;
          margin: -10em 1em 10em 1em;
          line-height: 1em;

          @include media($bp-landscape) {
            margin: -10em 2em 10em 2em;
          }

          @include media($bp-tablet) {
            margin: -10em 1em 10em 1em;
          }

          @include media($bp-smdesktop) {
            margin: -10em 0 10em 0;
          }

          @include media($bp-lgdesktop) {
            margin: -10em 2em 10em 2em;
          }
        }

        p {
          margin-top: -16em;
          margin-bottom: 6em;
          color: color("white");

          @include media($bp-smdesktop) {
            color: transparent;
            margin-bottom: 7.5em;
          }
        }

        img {
          z-index: -10;
          width: 100%;
        }

        &:hover {
          background-color: color("red");
          opacity: 0.8;
          margin-top: .1em;

          &:nth-child(2) {
            @include media($bp-tablet) {
              margin-bottom: 1.075em;
            }

            @include media($bp-desktop) {
              margin-bottom: 0;
            }
          }

          img {
            opacity: 0.1;
            background-color: color("red");
          }

          p, i {
            color: color("white");
            margin-bottom: 1.1em;
            padding-bottom: 5em;

            @include media($bp-tablet) {
              padding-bottom: 4.4em;
            }
          }
        }
      }

      .used-warranty {
        clear: both;

        #explain {
          width: 95%;
          text-align: center;
          margin-left: .5em;
          padding: 21.15% 1em;
          color: color("darkgray");
          border: 1px solid color("lightgray");

          @include media($bp-tablet) {
            padding: 3em;
            margin: 0 auto;
          }

          @include media($bp-desktop) {
            width: 50%;
            float: left;
            padding: 15em 3em 21.7em 3em;
            margin: 0;
            height: auto;
          }

          @include media($bp-lgdesktop) {
            padding: 15em 3em 17.55em 3em
          }


          img {
            width: 100%;
            height: auto;

            @include media($bp-tablet) {
              width: auto;
            }

            @include media($bp-desktop) {
              width: 100%;
            }

            @include media($bp-lgdesktop) {
              width: auto;
            }
          }

          h2 {
            margin: 0;
            font-weight: 900;
            line-height: 1.2em;
          }

          p {
            color: color("darkgray");
          }
        }

        #rating-ind {
          width: 95%;
          margin-left: .5em;
          padding: 2em 1em;
          background-color: color("palegray");
          border: 1px solid color("lightgray");

          @include media($bp-tablet) {
            margin: 0 auto;
          }


          @include media($bp-desktop) {
            width: 50%;
            float: left;
            margin: 0;
            padding: 2em;
          }

          @include media($bp-lgdesktop) {
            padding: 2em 3em;
          }

          h4 {
            color: color("red");
            text-transform: none;
            margin: 0;
            line-height: 1em;
          }

          p {
            color: color("darkgray");
          }
        }
      }
    }
  }
}