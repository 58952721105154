body, html {
    overflow-x: hidden;
}

#first-time { display: none; }

button {
  cursor: pointer;
  color: color("white");
  width: 50%;
  padding: 5px 0;
  background-color: color("red");
  border: 2px solid color("red");
  border-radius: 24px;
  height: 48px;
  text-transform: uppercase;

  @include media($bp-smdesktop) {
    width: 70%;
  }
}

.container {
  max-width: 1416px;
  min-width: 320px;
  margin: 0 auto;
}

.dealer-portal-link {
  margin-top: 50px;

  a {
    padding: 20px 50px;
    background-color: color("red");
    color: color("white");
    font-size: 1.2em;
  }
}
table.table {
  font-family: Lato, sans-serif;
  thead {
    tr {
      border-bottom: 1px solid #4b4f55;
      th {
        background-color: #f2f2f2;
        border-top: 1px solid #4b4f55;
        border-bottom: 3px solid #a8a8aa;
        text-align: left;
        padding: .5em .7em;
        font-size: 16px;
        color: rgb(75, 79, 85);
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #4b4f55;
      td {
        padding: .5em .7em;
        text-align: left;
        vertical-align: top;
        font-size: 16px;
      }
    }
  }
}

#sliding-popup {
  border-top: 1px solid #eee;
}
#sliding-popup .popup-content #popup-text h1, #sliding-popup .popup-content #popup-text h2, #sliding-popup .popup-content #popup-text h3, #sliding-popup .popup-content #popup-text p  {
  font-weight: normal;
}
div#popup-text,
div#popup-buttons {
  padding: 1em 0;
  margin: 0 !important;
}
div#popup-text p {
  font-size: 13px !important;
}
div#popup-text p a {
  color: #2f61bf;
}
div#popup-text .find-more-button {
  display: none;
}
div#popup-buttons .decline-button {
  display: none;
}
div#popup-buttons .agree-button {
  background: #264f9c;
  color: #fff !important;
  border-radius: 4px;
  display: block;
  padding: 5px 40px;
  text-align: center;
  margin: 0;
  margin-right: 0 !important;
  margin-top: 10px;
  height: auto;
  width: auto;
  font-size: 14px
}