
#header {
  width: 100%;
  position: fixed;
  padding: 0.5em 0;
  background-color: color("white");
  border-bottom: 1px solid color("lightgray");
  color: color("darkgray");
  z-index: 100;
  height: 3.5em;

  @include media($bp-tablet) {
    padding: 0;
  }

  @include media($bp-smdesktop) {
    height: 3.5em;
  }

  @include media($bp-desktop) {
    height: 4em;
    display: block;
  }

  &.sticky {
    background-color: color("white");
  }

  #header-logo {
    width: 85%;
    float: left;
    margin-left: .5em;

    @include media($bp-smdesktop) {
      width: 20%;
    }

    .main-logo {
      width: 45%;
      margin-top: -4px;

      @include media($bp-landscape) {
        width: 30%;
      }

      @include media($bp-tablet) {
        max-height: 60px;
        margin-left: 15px;
        padding-top: .7em;
      }

      @include media($bp-smdesktop) {
        width: 90%;
        max-height: none;
      }

      @include media($bp-desktop) {
        width: 80%;
      }

    }
  }

  .region-nav {
    width: 8%;
    float: left;

    @include media($bp-smdesktop) {
      width: 62%;

    }
  }

  .container {
    position: relative;
  }

  .search-wrapper {
    width: 100%;
    @include position(absolute, 50px null null 0);

    @include media($bp-tablet) {
      top: 60px;
    }

    @include media($bp-smdesktop) {
      width: auto;
      top: 0;
      right: 0;
      left: auto;
    }

    @include media($bp-lgdesktop) {
      //z-index: -5;
    }
  }
}

