.view-industry-landing {
  margin-top: 4em;
}

.page-industries .view-id-industry_landing .view-content {
  display: flex;
  align-items: space-between;
  justify-content: space-around;
  flex-flow: row wrap;
  align-content: flex-end;

  .views-row {
    margin: 40px 0;
    text-align: center;

    .field-content {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .views-field-nothing,
    .views-field-title {

      a {
        font-weight: 700;
        text-transform: uppercase;
        font-family: $button-font;

        &:last-child {
          margin: -6px 0 0;
          background-color: color("red");
          border: 1px solid color("red");
          padding: 10px;
          color: color("white");
        }
      }

      a:hover {
        color: color("red");
        font-weight: 700;
        background-color: color("white");
      }
    }

    .views-field-title:hover {
      background-color: color("white");
      color: color("red");

    }
  }
}

/* Other Industries blocks */
.industries-block {
  width: 100%;
  margin: 10px auto;
  h2 {
    text-align: center;
    color: color("darkgray");
    text-transform: capitalize;
    font-weight: 900;
  }
  .view-other-industries .view-header {
    margin: 20px auto;
  }
  .view-other-industries .view-content {
    display: flex;
    align-items: space-between;
    justify-content: space-around;
    flex-flow: row wrap;
    align-content: center;
    text-align: center;

    .views-field-title,
    .views-field-nothing{

      .field-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        a {
          font-weight: 700;
          text-transform: uppercase;
          font-family: $button-font;

          &:last-child {
            background-color: color("red");
            margin: -7px 23px 20px 0;
            padding: 10px;
            color: color("white");
            border: 1px solid color("red");
            width: 100%;
          }
        }
        a:hover {
          color: color("red");
          background-color: color("white");
          font-weight: 700;
        }
      }
    }
    .views-field-title:hover,
    .views-field-nothing a:hover{
      background-color: color("white");
      color: color("red");
    }
  }
}