.location-block {
  text-align: center;
  margin: 0 auto;

  .views-row-3, .views-row-4 {
    .views-field-title h4 {
      @include media($bp-smdesktop) {
        margin-top: 72px;
      }

      @include media($bp-lgdesktop) {
        margin-top: 35px;
      }
    }
  }

  h2 {
    text-align: center !important;
    margin-bottom: 0;
    font-weight: 900;
    color: color("darkgray");
  }

  p {
    width: 60%;
    margin: 10px auto;
  }

  .spec-location {
    text-align: center;

    @include media($bp-tablet) {
      width: 50%;
      float: left;
    }

    @include media($bp-smdesktop) {
      width: 25%;
      margin-bottom: 2em;
    }



    h4 {
      font-weight: 400;
      text-transform: capitalize;
      color: color("darkgray");
      margin-bottom: 20px;
      line-height: 1.3em;
    }

    .views-field-nothing {
      width: 70%;
      border-bottom: 1px solid color("darkgray");
      margin: -18px auto 10px;
    }

    .views-field-field-phone {
      margin: 1em auto;
    }

    .views-field-gsl-props-phone {
      margin: 3% auto;

      i {
        color: color("white");
        background-color: color("red");
        border-radius: 15px;
        font-size: 18px;
        padding-top: 6px;
        width: 30px;
        height: 30px;
      }
    }

    .country {
      display: none;
    }

    .contact-button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: color("white");
      width: 155px;
      padding: 5px 30px 5px;
      background-color: color("red");
      border: 2px solid color("red");
      border-radius: 24px;
      height: 48px;
      text-transform: uppercase;
      font-family: $button-font;
      margin: 15px auto;
    }

    .views-field-field-brands {
      color: color("darkgray");
      width: 70%;
      margin: 0 auto;
      padding: 20px 0;

      @include media($bp-smdesktop) {
        display: inline;
        justify-content: center;
      }

      @include media($bp-lgdesktop) {
        display: flex;
      }
    }
  }

}
