.page-dealer-locator {
  .region-content {
    display: block;
    width: 100%;

    #google-store-locator-map-container {
      margin-top: 1.1em;

      .google-store-locator-panel {
        float: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;

        @include media($bp-tablet) {
          width: 30%;
          float: left;
        }

        .storelocator-filter {
          width: 100%;
          border-bottom: 1px solid color("lightgray");
          padding-bottom: 1em;

          @include media($bp-tablet) {
            position: absolute;
            width: 29.9%;
            border-right: 1px solid color("lightgray");
            background-color: color("white");
          }

          @include media($bp-smdesktop) {
            width: 27.9%;
          }

          @include media($bp-extralgdesk) {
            width: 21.39%;
          }

          .location-search {
            h4 {
              text-transform: none;
              font-size: 28px;
              font-weight: 400;
              line-height: 1.556em;
              color: color("darkgray");
              text-align: center;
              padding-bottom: .5em;
            }

            input {
              width: 100%;
              height: 48px;
              padding-left: .5em;
              font-weight: 900;
              border: 1px solid color("lightgray");
            }
          }
        }

        .store-list {
          @include media($bp-tablet) {
            margin-top: 7.5em;
          }
        }

        .store {
          font-size: 1em;
          padding-bottom: .5em;

          &.highlighted {
            background: transparent;

            .title {
              color: color("red");
            }
          }

          .address {
            line-height: 1.5em;
            margin-bottom: 0;
          }

          .phone,
          .web {
            margin: 0;
          }

          a {
            color: color("red");
          }
        }
      }

      // Info bubbles
      .google-store-locator-map {

        @include media($bp-tablet) {
          left: -10px;
        }
        .store {
          font-size: 1em;
          padding-bottom: .5em;

          .title {
            color: color("red");
          }

          .address {
            line-height: 1.5em;
            margin-bottom: 0;
            color: color("darkgray");
          }

          .phone,
          .web {
            margin: 0 0 .5em 0;

            a {
              color: color("red");
            }
          }


        }
      }
    }
  }
}