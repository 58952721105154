.front-statement {
  background-color: color("red");
  padding: .5em 0;
  color: color("white");
}

/* statement alignment on the front page */
.front .front-statement .statement {
  margin: 10px;
  text-align: center;

  @include media($bp-tablet) {
    margin: 1% 15%;
    text-align: justify;
  }

  h4 {
    line-height: 1.5em;
    text-transform: none;
    font-size: 1em;
    margin: .5em 0;

    @include media($bp-tablet) {
      font-size: 1.2em;
      line-height: 1.5em;
    }

    @include media($bp-smdesktop) {
      font-size: 1.556em;
      line-height: 1em;
    }
  }
}
