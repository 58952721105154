.page-resources-videos {
  #content .container {
    max-width: 100%;
    margin-top: 4.5em;

    @include media($bp-tablet) {
      margin-top: 0;
    }
  }

  #breadcrumb {
    background-color: color("red");
    margin: 0;
    padding: 1em 1em 0 1em;



    .breadcrumb {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;

      a, .delimiter {
        color: color("white");
      }
    }
  }

  .view-videos {

    .view-header {
      background-color: color("red");
      padding: 0 1em;
      margin-bottom: 3em;

      .team-header {
        max-width: 1416px;
        min-width: 320px;
        margin: 0 auto;
        color: color("white");
      }
    }

    .view-content {
      max-width: 1416px;
      min-width: 320px;
      margin: 2em auto;

      @include media($bp-tablet) {
        display: flex;
        flex-wrap: wrap;
      }

      .video-row {
        margin-bottom: 1em;
        //text-indent: 1.5em;
        color: color("darkgray");
        text-align: center;

        @include media($bp-tablet) {
          margin: 1em;
          text-indent: initial;
          width: 45%;
          //text-align: left;
        }

        @include media($bp-smdesktop) {
          width: 29%;
          //text-indent: 1.5em;
        }
      }
    }

    .item-list {
      max-width: 1416px;
      min-width: 320px;
      margin: 2em auto;

      ul {
        float: right;

        li {
          padding: 10px 20px;
          margin: 0 1px;
          border: 1px solid color("lightgray");
          color: color("red");
          font-weight: 900;
          text-transform: uppercase;

          &.pager-first,
          &.pager-last {
            display: none;
            visibility: hidden;
          }

          &.pager-current {
            background-color: color("red");
            color: color("white");
          }

          &:hover {
            background-color: color("red");
            color: color("white");
          }
        }
      }
    }
  }
}
.view-videos .embedded-video {
  position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%;
}
.view-videos .embedded-video iframe,
.view-videos .embedded-video object,
.view-videos .embedded-video embed {
  position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}
