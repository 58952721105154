.used-equipment {
    margin: 1em 1em 2em 1em;

  @include media($bp-smdesktop) {
    margin: 4em 1em 8em 1em;
    padding-left: 5em;
  }

  #used-info {
    color: color("darkgray");
    margin-bottom: 2em;

      @include media($bp-tablet) {
        width: 50%;
        float: left;
    }

    h2 {
      text-transform: capitalize;
      font-weight: 900;
      text-align: left;
    }
  }

  img {
    width: 100%;

    @include media($bp-tablet) {
      width: 50%;
      padding-left: 1em;
    }
  }

  button {
    @include media($bp-smdesktop) {
      width: 40%;
    }
  }
}

.used-equipment-block {
  h4 {
    color: color("darkgray");
    text-transform: capitalize;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    color: color("darkgray");

    img {
      width: 100%;
    }
  }

  &.desktop {
    display: none;
    visibility: hidden;

    @include media($bp-tablet) {
      display: block;
      visibility: inherit;
    }
  }

  &.mobile {

    @include media($bp-tablet) {
      display: none;
      visibility: hidden;
    }

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    input:checked ~ .tab-content {
      max-height: 10%;
    }

    input[type=checkbox] + label::after,
    input[type=checkbox] + h4::after {
      font-family: FontAwesome;
      content: "\f107";

      @include media($bp-tablet) {
        display: none;
      }
    }

    input[type=checkbox]:checked + label::after,
    input[type=checkbox]:checked + h4::after {
      transform: rotateX(180deg);
      top: .7em;
    }

    label {
      border-top: 1px solid color("lightgray");
      border-bottom: 1px solid color("lightgray");
    }

    label,
    h4 {
      position: relative;
      display: block;
      padding: 0.5em 0 0.5em 0.3em;
      font-weight: 900;
      line-height: 1.111em;
      margin: 0;
      cursor: pointer;
      color: color("red");
      text-transform: capitalize;

    }


    label::after,
    h4::after {
      position: absolute;
      right: 0;
      top: 1.3em;
      display: block;
      width: 3em;
      height: 2em;
      text-align: center;
      transition: all.35s;
    }

    .tab-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height .35px;

      p {
        margin: 0 0.8em;
        color: color("darkgray");

        &:last-child {
          margin-top: 1em;
        }
      }

      button {
        margin: 1em;
        padding: 0;
      }
    }

  }
}