.node-slide {
  .slide-content {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    flex: 1 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1em 2em;
    min-height: 440px !important;
    
    .slide-image {
      position: relative;
      margin-bottom: 1em;
      z-index: 5;
      flex: 1 100%;
      order: 2;

      img {
        height: 360px;
        width: auto;
        max-width: 400px;
      }

      @include media($bp-desktop) {
        flex: 1 40%;
        max-width: 40%;

        img {
          height: 360px;
          width: auto;
          max-width: 9999px;
        }
      }
    }
    .slide-link {
      a {
        display: inline-block;
        margin: 1em auto;
        background: color("red");
        border-radius:1.5em;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0em 1.3em;
        opacity: 1;
        transition: all ease 0.3s;
        line-height: 2.65em;
        color: color("white");
        font-family: $button-font;
        &:after {
          font-family: FontAwesome;
          content: "\f054";
          margin-left: 5px;
          font-weight: normal;
        }
      }
    }
    .slide-link-cover {
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 5;
    }
    .slide-text {
      flex: 1 100%;
      padding: 1em;
      position: relative;
      z-index: 4;
      text-align: left;
      margin-bottom: 1em;
      order: 1;
      overflow: hidden;

      @include media($bp-desktop) {
        flex: 1 60%;
        padding: 0 0 0 5em;
        height: 20.556em;
        max-width: 60%;
        &.left {
          order: 3;
        }

      }

      tbody {
        border-style: none;
      }

      

      blockquote {
        margin: 0;
        margin-left: 0.35em;
        font-family: $header-font;
        color: color("superdarkgray");
        p {
          line-height: 1.7em;
          &:first-child {
            margin-top: 0.5em;
          }
        }

        em {
          background: color("red");
          padding: 0.167em;
          color: color("superdarkgray");
          font-weight: bold;
          font-style: normal;
          margin-right: 0.222em;
        }

      }
      
      h2, h3 {
        text-transform: capitalize;
      }

      h2, h3 {
        margin: 0 0 10px 0;
        padding: 0;
        line-height: 1em;
        font-weight: bold;
        text-align: left;
        color: color("white");
        text-shadow: 0.01em .021em 0.07em color("superdarkgray");
        font-size: 4em;
        em {
          color: color("red");
          font-style: normal;
        }
      }

      h3 {
        font-size: 1.667em;
        font-weight: 600;
        line-height: 1.944em;
      }
      @media all and (max-width: 768px) {
        h2 {
          font-size: 2.5em;
        }
        h3 {
          font-size: 1.4em;
          line-height: 1.3em;
        }
      }

    }
  }
}
.views-slideshow-cycle-main-frame-row {
  width: 100% !important;
  min-height: 440px !important;
}
.views_slideshow_controls_text {
  position: absolute;
  font-size: 72px;
  color: color("white");
  top: 40%;
  cursor: pointer;
  width: 100%;
  height: 72px;
  opacity: 1;
  transition: all ease .3s;
  z-index: 90;
  text-shadow: 5px 5px 3px #000;

  > span {
    position: absolute;
    z-index: 99;
    transition: all ease 0.3s;
    padding: 0.2em;
    a {
      display: block;
      overflow: hidden;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      span {
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.views-slideshow-controls-text-next {
      display: block;
      right: 0.2em;
      text-decoration: none;
      &:hover {
        right: 0.4em;
        text-decoration: none;
      }
    }

    &.views-slideshow-controls-text-previous {
      display: block;
      left: 0.2em;
      text-decoration: none;
      &:hover {
        left: 0.4em;
        text-decoration: none;
      }
    }
  }
}
.views-slideshow-simple-pager {
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  z-index: 100;
  > div {
    z-index: 100;
    display: inline-block;
    background-color: color("white");
    height: 0.778em;
    width: 0.778em;
    border-radius: 0.389em;
    &.active {
      background-color: color("red");
      border: 0.111em solid #fff;
      box-shadow: 0 0 0 0.111em color("red");
    }
  }
}
.view-home-page-slideshow {
  position: relative;
}
.views-slideshow-cycle-processed .views-slideshow-controls-top {
  display: none !important;
}

.view-home-page-slideshow {
  .view-content {
    display: none;
    visibility: hidden;

    @include media($bp-tablet) {
      display: block;
      visibility: inherit;
    }
  }
  .view-footer {
    @include media($bp-tablet) {
      display: none;
      visibility: hidden;
    }
    .slideshow-mobile {
      margin: 0.3em 0 -0.4em 0;
      img {
        width: 100%;
        height: 25%;
      }
    }
  }
}