#block-search-form,
#block-paladin-utils-search {
  width: 100%;

  @include media($bp-smdesktop) {
    width: auto;
    margin-right: 1em;
  }

  @include media($bp-desktop) {
    margin: 1em 0.5em 0 0;
  }

  @include media($bp-lgdesktop) {
    margin: 0.5em 0.5em 0 0;
  }

  .search-form, #paladin-utils-search-form {
    margin-bottom: 0;
  }

  .container-inline,
  #paladin-utils-search-form > div {
    display: flex;
    align-items: center;
    width: 100%;

    div {
      display: block;
    }

    .element-invisible {
      display: none;
    }

    .form-item, .form-actions {
      margin-top: 0;
      margin-bottom: 0;
    }

    .form-type-textfield {
      flex-grow: 1;
      width: 250px;

      @include media($bp-desktop) {
        width: 150px;
      }

      @include media($bp-lgdesktop) {
        width: 80px;
      }

      @include media($header-only) {
        width: 180px;
      }

      @include media($header-only-lg) {
        width: 220px;
      }
    }
  }

  .custom-search-selector,
  .custom-search-box,
  .custom-search-button,
  #paladin-utils-search-form input[type=image],
  #paladin-utils-search-form input[type=text] {
    display: block;
    height: 36px;

    @include media($bp-smdesktop) {
      height: 25px;
    }

    @include media($header-only-lg) {
      height: 32px;
    }
  }

  .custom-search-selector {
    background-color: color("lightgray");
    color: color("white");
    font-size: 0.778em;
  }

  .custom-search-box,
  #paladin-utils-search-form input[type=text] {
    width: 100%;
    font-family: $button-font;
    color: color("darkgray");
    font-size: 0.778em;
    font-style: italic;
    text-indent: 0.5em;
  }
}
