.node-type-dealer-assets {
  // JM - The product tabs for Specs, Descriptions, and Lit
  .field-group-easy-responsive-tabs-nav-wrapper {
    margin: 4em 0;
    width: 100%;
    flex: 1 100%;
    background-color: color("darkgray");
    clear: both;

    // For the mobile accordions
    .resp-tabs-container {
      border: 0.056em solid color("darkgray");
      width: auto;

      @include media($bp-tablet) {
        border: none;
      }

      h2 {
        background: color("darkgray");
        color: color("white");
        font-size: 1.5em !important;


        .resp-arrow {
          border-color: color("white") transparent;
        }
      }
    }

    .resp-tab-active {
      background: color("red") !important;
      color: color("white");

      h2 {
        font-size: 1.5em;
      }


      .resp-arrow {
        border-color: transparent transparent color("white") transparent;
      }
    }

    .resp-arrow {
      margin-top: 0.6em;
    }

  }

  // for tablet and higher


  .field-group-easy-responsive-tabs-processed {
    border: none;
    color: color("darkgray");
    position: relative;

    .resp-tabs-container {
      @include media($bp-smdesktop) {
        min-height: 300px;
      }
    }


    .resp-tab-content {
      @include media($bp-tablet) {
        border: none;
        font-size: 0.889em;
        line-height: 1.222em;
      }
    }

    ul.resp-tabs-list {
      display: block!important;
      li {
        display: none;
        height: 2.2em;
        line-height: 1.5em;
        padding: 0.722em 2em;
      }
    }

    @include media($bp-tablet) {
      ul.resp-tabs-list {
        display: block;
        li {
          display: inline-block;
        }
      }


    }

    ul.resp-tabs-list li {
      margin-right: -1px;
      padding-top: .4em;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      background-color: color("darkgray");
      color: color("white");
      font-family: $button-font;
      font-size: 16px;

      a {
        background-color: color("red");
        color: color("white");
      }
    }

    ul.resp-tabs-list li.resp-tab-active {
      background-color: color("red");
      color: color("white");
      border: none;
      padding: 0.4em 2em!important;

      // The funny little arrow for the active tab
      &:before {
        content: "";
        border-style: solid;
        position: absolute;
        margin-top: 1.5em;
        font-size: 1.2em;
        margin-left: 3%;
        border-width: 0.7em 1em 0 1em;
        border-color: color("red") transparent transparent transparent;
      }
    }
  }
}