.page-support-customer-support,
.page-about-us-leadership {
  #content .container {
    max-width: 100%;
  }

  #breadcrumb {
    background-color: color("red");
    margin: 0;
    padding: 1em 1em 0 1em;

    .breadcrumb {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;

       a, .delimiter {
         color: color("white");
       }
    }
  }

  .view-customer-support,
  .view-leadership {
    &.view-display-id-page {
      .view-header {
        background-color: color("red");
        margin-bottom: 3em;

        .team-header {
          max-width: 1416px;
          min-width: 320px;
          margin: 0 auto;
          color: color("white");
          padding: 0 .5em;
        }


        #div-header {
          background-color: color("white");

          .white {
            color: color("darkgray");
            max-width: 1416px;
            min-width: 320px;
            margin: 0 auto -2em;
            padding: 2em 1em;
            text-transform: none;
          }

          h3 {
            max-width: 1416px;
            min-width: 320px;
            margin: 0 auto;
          }
        }
      }
    }

    &.view-display-id-block_1,
    &.view-display-id-block_2 {
      background-color: color("white");
      color: color("red");

      .view-header {
        background-color: color("white") !important;

        .white {
          color: color("red");
          max-width: 1416px;
          min-width: 320px;
          margin: 0 auto;
        }
      }
    }

    .view-content {
      max-width: 1416px;
      min-width: 320px;
      margin: 2em auto;
    }
  }
}




.view-customer-support,
.view-leadership {
  .view-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  h1, h3 {
    text-align: left;
    color: color("red");
    text-transform: none;
  }

  .views-row {
    margin-right: 10px;
    margin-bottom: 3em;
    min-height: 460px;

    h4 {
      margin: 0 auto;
      color: color("darkgray");
      text-transform: none;
    }

    /* for the purpose of moving the section type closer when it exists */
    .views-field-field-section,
    .views-field-field-industry {
      margin-left: -5px;
    }

    .job-info {
      font-style: italic;
      color: color("darkgray");
      width: 80%;
      float: left;
      font-size: 16px;

      p {
        margin-top: 0;
      }
    }

    i {
      background-color: color("red");
      color: color("white");
      padding: 5px;
      margin-right: 10px;
      margin-top: 20px;
      border-radius: 20px;
      font-size: 18px;
      float: right;
    }
  }
}
