.node-type-products {
  .node-products {
    .content {
      .field-group-easy-responsive-tabs-nav-wrapper {
        .field-group-easy-responsive-tabs-processed {
          ul.resp-tabs-list {
            li:last-child {
              
            }
          }
        }

        .resp-tabs-container {
          
        }


      }
    }
  }
}

.field-name-field-conversion-block {
  width: 200px;
  display: block !important;
  padding: 0;
  border: .056em solid color("darkgray");
  background: color("white");

  form {
    display: flex;

    div {
      flex: 1 50%;
      min-width: 100px;
      white-space: nowrap;
      line-height: 2.1em;
      padding: 0 1em;
    }

    input[type=checkbox] { display:none; } /* to hide the checkbox itself */
    input[type=checkbox] + label:before {
      font-family: FontAwesome;
      display: inline-block;
    }

    input[type=checkbox] + label:before { content: "\f096"; } /* unchecked icon */
    input[type=checkbox] + label:before { letter-spacing: 10px; } /* space between checkbox and label */

    input[type=checkbox]:checked + label:before { content: "\f046"; } /* checked icon */
    input[type=checkbox]:checked + label:before { letter-spacing: 5px; } /* allow space for check mark */
  }
}
