.contact {
  z-index: 0;
  position: relative;
}

.address_list {
  display: none;
}
.contact-region {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  #map {
    height: 500px;
    background-color: color("lightgray");
    order: 1;
    flex: 1 100%;

    h1 {
      margin: 0;
    }
  }

  .container {
    width: 25%;
    height: 50%;
    z-index: 10;
    margin: 3% 0.278em 0.278em 0.278em;
    cursor: pointer;
    text-align: left;
    padding: 0.278em;
    background-color: color("white");
    position: relative;
  }

  .locations-region {
    background-color: color("white");
    padding: .4em 1.5em;
    width: 95%;
    flex: 1 100%;
    z-index: 6;

    @include media($bp-tablet) {
      width: 95%;
    }

    @include media($bp-smdesktop) {
      width: 55%;
      margin-top: .5%;
      position: absolute;
      top: 1em;
      left: 3.5em;
    }

    @include media($bp-desktop) {
      width: 452px;
    }

    @include media($bp-lgdesktop) {
      left: 16%;
    }

    .dropdown-wrapper {


      .dropdown {
        border: 0.056em solid color("darkgray");
        color: color("red");
        padding: 0.278em;
      }
      .address {
        padding: .567em 1.2em;
      }

      .fa-phone {
        background-color: color("red");
        color: color("white");
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 6px 8px;
      }

      .contact-button {
        cursor: pointer;
        color: color("white");
        width: 80%;
        height: 48px;
        text-align: center;
        padding: 8px 0 0 0;
        background-color: color("red");
        border: 2px solid color("red");
        border-radius: 24px;
        font-family: $button-font;
        text-transform: uppercase;

        @include media($bp-tablet) {
          width: 50%;
        }

        @include media($bp-smdesktop) {
          width: 40%;
        }

        @include media($bp-desktop) {
          width: 30%;
        }
      }

      a.toggle {
        text-decoration: none;
        text-align: left;
        font-weight: 900;
        padding: 0 1em;
        line-height: 2em;

        span {
          float: right;
          margin-top: 0.5em;
          margin-right: 1em;
          color: color("red");
          transition: all ease .3s;
        }

        &.open span {
          transform: rotate(180deg);
        }
      }

      .dropdown-content {
        border: 0.056em solid color("lightgray");
        position: absolute;
        background-color: color("white");
        margin-top: 0.278em;
        margin-left: -0.3em;
        width: 90%;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            a {
              display: block;
              color: color("darkgray");
              padding: 0.5em;
              border-bottom: 0.056em solid color("lightgray");
              text-decoration: none;
            }
          }
        }
      }
    }

    h2 {
      position: static;
      margin: 0;
      text-transform: capitalize;
      font-weight: 900;
      margin-bottom: 0.5em;
      line-height: 1.5em;
      color: color("darkgray");
    }
  }

}

/* The Contact Us form page */

.node-type-webform .region-content {
  @include media($bp-smdesktop) {
    width: 50%;
    display: flex;
    float: left;
  }

  @include media($bp-lgdesktop) {
    width: auto;
  }

  .node-webform {
    color: color("darkgray");
    margin-left: 1em;
    margin-right: 1em;

    @include media($bp-tablet) {
      margin-left: 2em;
      margin-right: 2em;
    }

    @include media($bp-smdesktop) {
      margin-left: 3em;
      margin-right: 3em;
    }

    @include media($bp-desktop) {
      margin-left: 2.5em;
      margin-right: 4em;
    }

    label {
      margin-left: 0.833em;
    }

    input, textarea, select {
      border: 0.056em solid color("lightgray");
      padding: 0.278em;
      width: 100%;
    }

    select {
      font-family: $body-font;
      color: color("darkgray");
    }

    .webform-submit {
      cursor: pointer;
      color: color("white");
      width: 60%;
      height: 48px;
      text-align: center;
      margin: 0;
      background-color: color("red");
      border: 0.111em solid color("red");
      border-radius: 24px;
      font-family: $button-font;
      text-transform: uppercase;

      @include media ($bp-tablet) {
        width: 50%;
      }

      @include media($bp-smdesktop) {
        width: 30%;
      }
    }
  }
}

.node-type-webform {
  .article-mailing-list {
    background-color: color("red");
    color: color("white");
    width: 100%;
    margin-bottom: -1em;

    @include media($bp-landscape) {
      margin-bottom: -5px;
    }

    .content {
      max-width: 1416px;
      min-width: 320px;
      margin: 0 auto;
    }

    h2 {
      color: color("white");
      font-weight: 900;
      text-align: left;
      margin-left: 0.5em;
      padding-top: 0.5em;

      @include media($bp-smdesktop) {
        width: 40%;
        margin: 2% 10% 0 5%;
      }

      @include media($bp-desktop) {
        width: 35%;
        margin: 2% 15% 0 10%;
      }
    }

    p {
      text-wrap: wrap;
      margin: 0 1em;

      @include media($bp-smdesktop) {
        width: 40%;
        float: left;
        margin: 0 5% 3% 5%;
      }

      @include media($bp-desktop) {
        width: 35%;
        margin: 0 10% 3% 10%;
      }
    }

    .webform-client-form {
      margin: 1em;

      @include media($bp-smdesktop) {
        float: left;
        width: 45%;
        margin: -20px 0 0 0;
      }

      .form-item, .form-actions {
        display: inline;
      }

      .form-item input {
        width: 75%;
        height: 60px;
        color: color("black");

        @include media($bp-landscape) {
          width: 60%;
        }
      }

      .webform-submit.button-primary {
        background-color: color("red");
        color: color("white");
        font-family: $button-font;
        text-transform: uppercase;
        border: 2px solid color("white");
        margin-left: -.3em;
        padding: .60em 0 .59em 0;
        height: 60px;
        width: 25%;
      }
    }
  }
}

.node-type-webform {
  .region-sidebar-right {
    width: auto;
  }
}

.node-type-webform #sidebar-second {

  .contact-support {
    background: url('../../../../../../../sites/default/files/support-bkg-medium.jpg') top no-repeat;
    color: color("white");
    height: 550px;

    .details {
      width: 90%;
      text-align: center;
      margin: 0 auto;
      height: 550px;
      padding-top: .556em;
    }

    h3 {
      text-align: center;
    }

    button {
      width: 60%;
      margin-bottom: .89em;
      font-family: $button-font;
      text-transform: uppercase;
    }
  }

  @include media($bp-smdesktop) {
    width: 45%;
    float: right;

    .contact-support {
      margin-top: 5%;

      .details {
        padding-top: 100px;
        width: 85%;
      }

      button {
        width: 48%;
      }
    }
  }

  @include media($bp-desktop) {
    width: 50%;

    .contact-support {
      .details {
        padding-top: 150px;
        width: 70%;
      }

      button {
        width: 40%;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {

  #sidebar-second {
    width: 30%;
  }

  //input, textarea, select, .grippie {
  //  width: 50% !important;
  //}

}
